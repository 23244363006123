import { createSelector } from 'reselect';
const selectorComponent = state => state.Component;
const selectorAppConfig = state => state.ConfigApp;

export const baseAPISelector = () =>
  createSelector(selectorAppConfig, state => state.appConfig.baseAPI);
export const tempLastActionSelector = () =>
  createSelector(selectorAppConfig, state => state.tempLastAction);
export const baseMainAPISelector = () =>
  createSelector(selectorAppConfig, state => state.appConfig.baseMainAPI);
export const appNameSelector = () =>
  createSelector(selectorAppConfig, state => state.appConfig.appName);

export const siderIsColapseSelector = () =>
  createSelector(selectorComponent, state => state.siderIsColapse);
export const drawerProfileIsColapseSelector = () =>
  createSelector(selectorComponent, state => state.drawerProfileIsColapse);
export const drawerMenuIsColapseSelector = () =>
  createSelector(selectorComponent, state => state.drawerMenuIsColapse);
export const isLoading = () =>
  createSelector(selectorComponent, state => state.isLoading);
export const isLoadingAlternate = () =>
  createSelector(selectorComponent, state => state.isLoadingAlternate);
export const modalIsShow = component =>
  createSelector(selectorComponent, state => state[`showModal${component}`]);
export const lightBoxIsOpen = component =>
  createSelector(
    selectorComponent,
    state => state[`lightBox${component}IsOpen`]
  );
