import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsLessonPlan from '../Selector/LessonPlanSelector';

import { bindActionCreators, compose } from 'redux';
import { getFormSyncErrors, getFormValues } from 'redux-form';

import ModalFormLessonPlanComponent from '../Component/ModalFormLessonPlanComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function ModalFormLessonPlanContainer(props) {
  const { componentAction, lessonPlanAction, detailLearningActivity } = props;

  const handleCancel = () => {
    componentAction.openModal('FormLessonPlan');
    lessonPlanAction.resetForm();
    lessonPlanAction.resetFormLessonPlan();
  };
  const handleSubmit = () => {
    lessonPlanAction.submitLessonPlanRequested();
  };

  const initialValues: any = {
    ...detailLearningActivity?.lessonplan,
    mainTheme: detailLearningActivity?.maintheme,
  };

  return (
    <ModalFormLessonPlanComponent
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  formLessonPlanModalIsShow: SelectorsComponent.modalIsShow('FormLessonPlan'),
  errorFormValue: getFormSyncErrors('lessonPlanForm'),
  detailLearningActivity:
    SelectorsLessonPlan.selectedLearningActivitySelector(),
  formValue: getFormValues('lessonPlanForm'),
});

const mapDispatchToProps = dispatch => ({
  lessonPlanAction: bindActionCreators(ActionLessonPlan, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalFormLessonPlanContainer));
