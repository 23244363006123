import {
  ISetAppName,
  ISetBaseAPI,
  ISetBaseMainAPI,
  ISetTempLastAction,
} from './AppAction';

import { Action } from 'redux';

export const initialState: any = {
  appConfig: {
    baseAPI: process.env.REACT_APP_API_URL,
    baseMainAPI: process.env.REACT_APP_API_MAIN_URL,
    appName: 'odysee',
  },
  tempLastAction: [],
};

export default function AppConfigReducer(state = initialState, action: Action) {
  if (!action) {
    return state;
  }
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'SET_BASE_API_URL': {
      const setBaseAPI = action as ISetBaseAPI;
      newState.appConfig.baseAPI = setBaseAPI.baseAPI;
      return { ...newState };
    }
    case 'SET_BASE_MAIN_API_URL': {
      const setBaseMainAPI = action as ISetBaseMainAPI;
      newState.appConfig.baseMainAPI = setBaseMainAPI.baseMainAPI;
      return { ...newState };
    }
    case 'SET_APP_NAME': {
      const setAppName = action as ISetAppName;
      newState.appConfig.appName = setAppName.value;
      return { ...newState };
    }
    case 'RESET_BASE_API_URL':
      newState.appConfig.baseAPI = process.env.REACT_APP_API_URL;
      return { ...newState };
    case 'SET_TEMP_LAST_ACTION': {
      const setTempLastAction = action as ISetTempLastAction;
      const lastAction = [...state.tempLastAction];
      lastAction.push(setTempLastAction.action);
      newState.tempLastAction = lastAction;
      return { ...newState };
    }
    case 'RESET_TEMP_LAST_ACTION':
      newState.tempLastAction = [];
      return { ...newState };
  }
  return state;
}
