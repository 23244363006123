const LoginValidation = values => {
  const errors: any = {};
  if (!values.username) {
    errors.username = 'Username required!';
  }
  if (!values.password) {
    errors.password = 'Password required!';
  }
  return errors;
};
export default LoginValidation;
