import { Button, Col, Form, Input, Layout, Row } from 'maveth';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import ModalResetPasswordContainer from '../Container/ModalResetPasswordContainer';
import React from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import backgroundImage from '../../../Assets/Images/backgroundlogin.jpg';
import logoImage from '../../../Assets/Images/logo.png';
import validate from '../Validation/LoginValidation';

interface IProps {
  submitLogin;
  appName;
  isLoading;
  intl;
  theme;
  handleForgotPassword;
}

const InputUsername = props => {
  const { input, placeholder } = props;
  return (
    <Form.Item>
      <Input placeholder={placeholder} {...input} />
    </Form.Item>
  );
};

const InputPassword = props => {
  const { input, placeholder } = props;

  return (
    <Form.Item>
      <Input.Password placeholder={placeholder} {...input} />
    </Form.Item>
  );
};

function FormLoginComponent(props: IProps & InjectedFormProps) {
  const { Content } = Layout;
  const { submitLogin, isLoading, invalid, intl, theme, handleForgotPassword } =
    props;
  const { thirdColor } = theme;

  const submitForm = (e: any) => {
    submitLogin();
  };
  return (
    <>
      <Content className="backgroundLoginComponent">
        <Row className="rowLogin">
          <Col
            span={24}
            className="colLoginLeft"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <p className="titleLogin">
              <span className="appNameLogin">
                <img
                  alt="logo"
                  src={logoImage}
                  style={{
                    width: '200px',
                    margin: 'auto',
                    display: 'block',
                    marginBottom: '30px',
                  }}
                />
              </span>
            </p>
            <p className="descriptionLogin">
              {intl.formatMessage({ id: 'taglineLogin' })}
            </p>
            <div className="divFormLogin">
              <h1 className="titleFormLogin" style={{ color: thirdColor }}>
                {intl.formatMessage({ id: 'titleLogin' })}
              </h1>
              <p className="descriptionFormLogin">
                {intl.formatMessage({ id: 'descriptionLogin' })}
              </p>
              <Form className="formLogin" onFinish={submitForm}>
                <Field
                  name="username"
                  component={InputUsername}
                  placeholder={intl.formatMessage({ id: 'username' })}
                />
                <Field
                  name="password"
                  component={InputPassword}
                  placeholder={intl.formatMessage({ id: 'password' })}
                />
                <p
                  onClick={handleForgotPassword}
                  className="forgotPasswordLogin"
                >
                  {intl.formatMessage({ id: 'forgotPasswordLabel' })}
                </p>
                <Form.Item className="formItemBtnLogin">
                  <Button
                    type="primary"
                    className="btnLogin"
                    htmlType="submit"
                    icon={<UnlockOutlined />}
                    loading={isLoading}
                    disabled={invalid}
                  >
                    {intl.formatMessage({ id: 'login' })}
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <p className="copyrightLogin">
              Copyright © {new Date().getFullYear()}
            </p>
          </Col>
        </Row>
        <ModalResetPasswordContainer />
      </Content>
    </>
  );
}

const LoginComponent = reduxForm({
  form: 'loginForm',
  validate,
})(FormLoginComponent);
export default LoginComponent;
