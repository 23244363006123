import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch } from 'react-router-dom';

import AuthMiddleware from './AuthMiddleware';
import DashboardContainer from '../Modules/Dashboard/Container/DashboardContainer';
import { Helmet } from 'react-helmet';
import LoginContainer from './../Modules/Auth/Container/LoginContainer';
import NotFoundComponent from '../Assets/Components/NotFoundComponent';
import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import ResetPasswordContainer from '../Modules/Auth/Container/ResetPasswordContainer';
import SchoolContainer from '../Modules/School/Container/SchoolContainer';
import TemplateContainer from '../Modules/Template/Container/TemplateContainer';
import { ToastContainer } from 'react-toastify';
import WithTemplate from '../App/WithTemplate';

export default function Navigation() {
  const login = LoginContainer;
  const resetPassword = ResetPasswordContainer;
  const dashboard = AuthMiddleware(
    WithTemplate(TemplateContainer, DashboardContainer)
  );
  const school = AuthMiddleware(
    WithTemplate(TemplateContainer, SchoolContainer)
  );
  return (
    <>
      <Helmet titleTemplate="Odysee" defaultTitle="Odysee">
        <meta
          name="description"
          content="An POS system for helping online shop"
        />
      </Helmet>
      <ReduxToastr
        timeOut={3000}
        newestOnTop={false}
        preventDuplicates={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={true}
        closeOnToastrClick={true}
      />
      <ToastContainer autoClose={3000} />
      <Switch>
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/login`}
          component={login}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/resetpassword/:link`}
          component={resetPassword}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/`}
          component={dashboard}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/school`}
          component={school}
        />
        <Route
          exact={true}
          path={`${process.env.PUBLIC_URL}/404`}
          component={NotFoundComponent}
        />
        <Route path="*" component={NotFoundComponent} />
      </Switch>
    </>
  );
}
