import { Button, Col, Drawer, Menu, Row } from 'maveth';
import {
  CloseOutlined,
  LineHeightOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Link } from 'react-router-dom';
import React from 'react';
import { configMenuSider } from '../../../App/MenuList';

const { SubMenu } = Menu;

export default function DrawerMenuComponent(props) {
  const {
    drawerMenuIsCollapse,
    handleDrawerCloseClick,
    handleDrawerProfileClick,
    intl,
    theme,
    handleShowModalESign,
  } = props;
  const { sixthBackground, thirdColor } = theme;

  const menuList = configMenuSider(intl);

  const renderTitle = () => {
    return (
      <p className="drawerTitle" style={{ color: thirdColor }}>
        {intl.formatMessage({ id: 'menuList' })}
      </p>
    );
  };

  const renderSubMenu = x => {
    const renderSub = renderMenuSider(x.componentMenuItem);
    let isNull = true;
    for (const iterator of renderSub) {
      if (iterator !== null) {
        isNull = false;
        break;
      }
    }
    if (isNull === false) {
      return (
        <SubMenu
          id={x.id}
          key={x.keyName}
          title={
            <span>
              {x.icon}
              <span>{x.label}</span>
            </span>
          }
        >
          {renderSub}
        </SubMenu>
      );
    }
    return null;
  };

  function renderMenuSider(menuData) {
    return menuData.map(x => {
      if (x.component === 'menuItem') {
        return (
          <Menu.Item key={x.path} id={x.id}>
            <Link to={`${x.path}`} onClick={handleDrawerCloseClick}>
              {x.icon ? x.icon : null}
              <span>{x.label}</span>
            </Link>
          </Menu.Item>
        );
      }
      if (x.component === 'subMenu') {
        return renderSubMenu(x);
      }
      return null;
    });
  }

  return (
    <Drawer
      title={renderTitle()}
      placement="right"
      closable={false}
      visible={drawerMenuIsCollapse}
      width={400}
      maskClosable
      onClose={handleDrawerCloseClick}
      className="drawerProfile"
      drawerStyle={{ background: sixthBackground, color: thirdColor }}
      headerStyle={{ background: sixthBackground, color: thirdColor }}
    >
      <Button className="btnCloseDrawer" onClick={handleDrawerCloseClick}>
        <CloseOutlined className="iconCloseDrawer" />
      </Button>
      <Row>
        <Col span={24}></Col>
      </Row>
      <Row>
        <Col span={24}>
          <Menu
            theme="light"
            mode="inline"
            style={{ width: '100%', height: '100%' }}
            className="templateMenu"
          >
            <Menu.Item onClick={handleDrawerProfileClick}>
              <UserOutlined />
              <span>Profile</span>
            </Menu.Item>
            <Menu.Item onClick={handleShowModalESign}>
              <LineHeightOutlined />
              <span>Signature</span>
            </Menu.Item>
            {renderMenuSider(menuList)}
          </Menu>
        </Col>
      </Row>
    </Drawer>
  );
}
