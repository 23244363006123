import {
  IFetchCurriculumDetailSuccessAction,
  ISetSelectedCurriculumAction,
} from './CurriculumAction';

import { Action } from 'redux';

export const initialState: any = {
  selectedCurriculum: '',
  selectedCurriculumData: {},
};
export default function CurriculumReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CURRICULUM_DETAIL_FINISHED': {
      const fetchCurriculumSuccessAction =
        action as IFetchCurriculumDetailSuccessAction;
      const data: any = fetchCurriculumSuccessAction.data;
      newState.selectedCurriculumData = data;
      newState.selectedCurriculum = data.id;
      return { ...newState };
    }
    case 'SET_SELECTED_CURRICULUM': {
      const setSelectedCurriculumAction =
        action as ISetSelectedCurriculumAction;
      newState.selectedCurriculum = setSelectedCurriculumAction.id;
      return { ...newState };
    }
  }
  return state;
}
