import * as ActionApp from '../../App/Store/AppAction';
import * as ActionStudent from '../Store/StudentAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorsClassroom from '../../Classroom/Selector/ClassroomSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getStudentListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorsClassroom.classroomSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/student/classroom/${id}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionStudent.fetchStudentListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getStudentListAction() {
  yield takeLatest('FETCH_STUDENT_LIST_REQUESTED', getStudentListProcess);
}
