export const darkTheme = {
  background: '#354052',
  secondaryBackground: '#252e3c',
  thirdBackground: '#262d3c',
  forthBackground: '#354052',
  fifthBackground: '#252e3c',
  sixthBackground: '#354052',
  color: '#ffffff',
  secondaryColor: '#ffffff',
  thirdColor: '#ffffff',
};

export const lightTheme = {
  background: '#263238',
  secondaryBackground: '#324148',
  thirdBackground: '#f5f5f5',
  forthBackground: '#ffffff',
  fifthBackground: '#dee2e6',
  sixthBackground: '#ffffff',
  color: '#263238',
  secondaryColor: '#ffffff',
  thirdColor: '#263238',
};
