import { Col, Collapse, Empty, Modal, Row, Tabs } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CCard from '../../../Assets/Components/CCard';
import CForm from '../../../Assets/Components/CForm';
import { FormattedMessage } from 'react-intl';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import ScoringComponent from '../../Scoring/Component/ScoringComponent';
import moment from 'moment';
import validate from '../../Scoring/Validation/ScoringValidation';

function FormModalStudentComponent(props) {
  const {
    handleClose,
    intl,
    handleOnChange,
    loading,
    selectedClassroomName,
    selectedGradeName,
    selectedSchoolName,
    studentList,
    studentModalIsShow,
    handleOnSubmit,
    scoringList,
    handleShowReportCard,
    scoringCriteriaList,
    scoringMonth,
  } = props;

  const monthNow = parseInt(moment().format('M'), 10);
  let disabledTabAt = 0;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="studentForm" /> : {selectedSchoolName} -{' '}
        {selectedGradeName} - {selectedClassroomName}
      </>
    );
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'studentList' })}
      </span>
    );
  };
  const cardContentTable = () => {
    return (
      <>
        <Row>
          <Col>
            <Field
              name="name"
              component={InputText}
              placeholder={intl.formatMessage({
                id: 'placeholderSearchStudentName',
              })}
              idComponent="inputName"
              className="inputSearchSchoolName"
              formClassName="formSearchClassName"
            />
          </Col>
        </Row>
        <Row>
          <Collapse
            style={{ width: '100%' }}
            onChange={handleOnChange}
            defaultActiveKey={undefined}
            accordion
          >
            {studentList.length === 0 ? (
              <div
                style={{ display: 'block', alignItems: 'center', padding: 30 }}
              >
                <Empty />
              </div>
            ) : null}
            {studentList.map(val => {
              let isBreak = false;
              return (
                <Collapse.Panel
                  header={val.name}
                  key={val.id}
                  destroyInactivePanel
                >
                  <Tabs type="card">
                    {scoringMonth.map((valTrimester, idxTrimester) => {
                      const { start, end } = valTrimester;
                      if (
                        isBreak === false &&
                        monthNow >= (start > 12 ? start - 12 : start) &&
                        monthNow <= (end > 12 ? end - 12 : end)
                      ) {
                        disabledTabAt = idxTrimester;
                        isBreak = true;
                      }

                      const dataInitialValues = scoringList[val.id];
                      const initialValues: any = {};
                      if (dataInitialValues) {
                        const scoringData =
                          dataInitialValues.scoring[idxTrimester];
                        for (const key in scoringData) {
                          if (
                            Object.prototype.hasOwnProperty.call(
                              scoringData,
                              key
                            )
                          ) {
                            const element = scoringData[key];
                            initialValues[`${element.name}__${val.id}`] =
                              element.value;
                          }
                        }
                        if (dataInitialValues?.comment) {
                          initialValues[`comment__${val.id}`] =
                            dataInitialValues?.comment;
                        }
                      }

                      const semesterLabel = () => {
                        switch (idxTrimester) {
                          case 0:
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {`${intl.formatMessage({
                                  id: 'mid',
                                })} ${intl.formatMessage({
                                  id: 'semester',
                                })} 1`}
                              </span>
                            );

                          case 1:
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {`${intl.formatMessage({
                                  id: 'semester',
                                })} 1`}
                              </span>
                            );

                          case 2:
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {`${intl.formatMessage({
                                  id: 'mid',
                                })} ${intl.formatMessage({
                                  id: 'semester',
                                })} 2`}
                              </span>
                            );

                          case 3:
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {`${intl.formatMessage({
                                  id: 'semester',
                                })} 2`}
                              </span>
                            );

                          default:
                            return (
                              <span style={{ textTransform: 'capitalize' }}>
                                {`${intl.formatMessage({
                                  id: 'mid',
                                })} ${intl.formatMessage({
                                  id: 'semester',
                                })} 1`}
                              </span>
                            );
                        }
                      };

                      return (
                        <Tabs.TabPane
                          tab={semesterLabel()}
                          key={`trimester${idxTrimester}Tab${idxTrimester}`}
                          disabled={
                            isBreak
                              ? idxTrimester === disabledTabAt
                                ? false
                                : true
                              : false
                          }
                        >
                          <CForm
                            initialValues={initialValues}
                            onSubmit={e => {
                              handleOnSubmit(e, val.id, idxTrimester);
                            }}
                            subscription={{ initialValues: true }}
                            validate={valData =>
                              validate(
                                valData,
                                val.id,
                                scoringCriteriaList,
                                intl
                              )
                            }
                            component={data =>
                              ScoringComponent(
                                data,
                                intl,
                                val.id,
                                idxTrimester,
                                handleShowReportCard,
                                scoringCriteriaList
                              )
                            }
                          />
                        </Tabs.TabPane>
                      );
                    })}
                  </Tabs>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </Row>
      </>
    );
  };
  const contentComponent = () => {
    return (
      <div className="cardStudentList">
        <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
      </div>
    );
  };
  return (
    <>
      <Modal
        isShow={studentModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        handleClose={handleClose}
        isLoading={loading}
      />
    </>
  );
}
const ModalStudentComponent = reduxForm({
  form: 'studentForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
})(FormModalStudentComponent);
export default ModalStudentComponent;
