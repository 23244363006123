import * as ActionApp from '../../App/Store/AppAction';
import * as ActionAuth from '../Store/AuthAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAuth from '../Selector/AuthSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';

import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import history from '../../../App/History';
import { toast } from 'react-toastify';

let toastMessage;

export function* loginProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const username = yield select(SelectorAuth.usernameSelector());
    const password = yield select(SelectorAuth.passwordSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.post, `${API_URL}/auth/login`, {
      username,
      password,
    });
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.collapseDrawerProfile(true));
    yield put(ActionAuth.setLoginTokenData(data));
    history.push('/');
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="loginSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* renewTokenProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/auth/token/refresh`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionAuth.setLoginTokenData(data));

    const lastAction = yield select(SelectorConfig.tempLastActionSelector());
    for (const iterator of lastAction) {
      if (iterator.actionData) {
        yield put(iterator.actionData);
      }
    }
  } catch (error) {
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id={'sessionExpired'} />
      </span>
    );
    yield put(ActionAuth.logout());
    history.push('/login');
    toast.error(toastMessage);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.clearTempLastAction());
  }
}

export function* changePasswordProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const oldpassword = yield select(SelectorAuth.changePasswordOldSelector());
    const newpassword = yield select(SelectorAuth.changePasswordNewSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.put,
      `${API_URL}/auth/changepassword`,
      {
        oldpassword,
        newpassword,
      },
      config
    );
    yield put(ActionAuth.logout());
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('ChangePassword'));
    yield put(ActionsComponent.collapseDrawerProfile(true));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="changePasswordSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    history.push('/login');
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
    yield put(ActionsComponent.processLoading(false));
  }
}
export function* resetPasswordProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const username = yield select(SelectorAuth.resetPasswordUsernameSelector());
    const email = yield select(SelectorAuth.resetPasswordEmailSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(axios.post, `${API_URL}/auth/passwordreset`, {
      username,
      email,
    });
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsComponent.openModal('ResetPassword'));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successSendResetPasswordLink" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
    yield put(ActionsComponent.processLoading(false));
  }
}

export function* checkUrlResetPasswordProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const url = action.link;
    yield put(ActionsComponent.processLoading(true));
    yield call(axios.get, `${API_URL}/auth/passwordreset/${url}`);
    yield put(ActionsComponent.processLoading(false));
  } catch (error) {
    history.push('/404');
  }
}

export function* setResetPasswordProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);

    const url = action.link;
    const password = yield select(SelectorAuth.setResetPasswordSelector());

    yield put(ActionsComponent.processLoading(true));
    yield call(
      axios.post,
      `${API_URL}/auth/passwordreset/setpassword`,
      {
        url,
        password,
      },
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionAuth.resetFormSetPasswordReset());
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="changePasswordSuccess" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    history.push('/login');
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
    yield put(ActionsComponent.processLoading(false));
  }
}
export function* captureRenewTokenProcess(action) {
  yield put(ActionApp.setTempLastAction(action.lastAction));
}

export function* loginAction() {
  yield takeLatest('SUBMIT_LOGIN_REQUESTED', loginProcess);
}
export function* renewTokenAction() {
  yield takeEvery('RENEW_TOKEN', captureRenewTokenProcess);
  yield takeLeading('RENEW_TOKEN', renewTokenProcess);
}
export function* changePasswordAction() {
  yield takeLatest('CHANGE_PASSWORD_REQUESTED', changePasswordProcess);
}
export function* resetPssswordAction() {
  yield takeLatest('RESET_PASSWORD_REQUESTED', resetPasswordProcess);
}
export function* setResetPssswordAction() {
  yield takeLatest('SET_RESET_PASSWORD_REQUESTED', setResetPasswordProcess);
}
export function* checkUrlResetPasswordAction() {
  yield takeLatest(
    'CHECK_URL_RESET_PASSWORD_REQUESTED',
    checkUrlResetPasswordProcess
  );
}
