import { Avatar, Grid, Menu } from 'maveth';
import { HighlightOutlined, UserOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';
import React from 'react';
import { configMenuSider } from '../../../App/MenuList';
import history from '../../../App/History';

const { SubMenu } = Menu;

export default function MenuComponent(props) {
  const {
    intl,
    theme,
    style,
    handleShowModalESign,
    user,
    handleOnClickDrawer,
  } = props;

  const defaultSelectedKeys = history.location.pathname;
  const { secondaryColor, secondaryBackground } = theme;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { xs } = screens;

  const menuSider = configMenuSider(intl);
  const renderSubMenu = x => {
    const renderSub = renderMenuSider(x.componentMenuItem);
    let isNull = true;
    for (const iterator of renderSub) {
      if (iterator !== null) {
        isNull = false;
        break;
      }
    }
    if (isNull === false) {
      return (
        <SubMenu
          id={x.id}
          key={x.keyName}
          style={{ color: secondaryColor }}
          title={
            <span>
              {x.icon}
              <span>{x.label}</span>
            </span>
          }
        >
          {renderSub}
        </SubMenu>
      );
    }
    return null;
  };

  function renderMenuSider(menuData) {
    return menuData.map(x => {
      if (x.component === 'menuItem') {
        return (
          <Menu.Item key={x.path} id={x.id}>
            <Link to={`${x.path}`} style={{ color: secondaryColor }}>
              {x.icon ? x.icon : null}
              <span>{x.label}</span>
            </Link>
          </Menu.Item>
        );
      }
      if (x.component === 'subMenu') {
        return renderSubMenu(x);
      }
      return null;
    });
  }

  return (
    <Menu
      selectedKeys={[defaultSelectedKeys]}
      mode={xs ? 'vertical' : 'horizontal'}
      style={{
        backgroundColor: secondaryBackground,
        color: secondaryColor,
        ...style,
        float: 'right',
      }}
      className="menuHeader"
    >
      {renderMenuSider(menuSider)}
      <Menu.Item onClick={handleShowModalESign}>
        <HighlightOutlined />
        <span>Signature</span>
      </Menu.Item>
      <Menu.Item onClick={handleOnClickDrawer}>
        <span>
          Hi, <b>{user.name}</b>
          <Avatar
            shape="square"
            icon={<UserOutlined />}
            className="iconProfileHeader"
            style={{ background: '#c9f7f5', color: '#1bc5bd' }}
          />
        </span>
      </Menu.Item>
    </Menu>
  );
}
