import { Action } from 'redux';
import { IFetchTrimesterSettingSuccessAction } from './TrimesterSettingAction';

export const initialState: any = {
  trimesterSettingData: null,
};
export default function TrimesterSettingReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_TRIMESTER_SETTING_FINISHED': {
      const fetchTrimesterSettingSuccessAction =
        action as IFetchTrimesterSettingSuccessAction;
      newState.trimesterSettingData = fetchTrimesterSettingSuccessAction.data;
      return { ...newState };
    }
  }
  return state;
}
