import { Action } from 'redux';
export interface IFetchLessonPlanDetailSuccessAction extends Action {
  data: object;
}
export interface ISetDetailListAction extends Action {
  data: object;
}
export interface ISetSelectedModalAction extends Action {
  action: string;
}
export interface ISetSelectedLearningActivityAction extends Action {
  data: object;
}
export interface ISetTopicDetailAction extends Action {
  topic: string;
}
export interface IDeleteDetailAction extends Action {
  index: number;
}
export interface ISetSelectedLessonPlan extends Action {
  id: string;
}
export function submitLessonPlanRequested(): Action {
  return {
    type: 'SUBMIT_LESSON_PLAN_REQUESTED',
  };
}
export function fetchLessonPlanDetailRequested(): Action {
  return {
    type: 'FETCH_LESSON_PLAN_DETAIL_REQUESTED',
  };
}
export function setSelectedModalAction(
  action: string
): ISetSelectedModalAction {
  return {
    type: 'SET_LESSON_PLAN_MODAL_ACTION',
    action,
  };
}
export function setSourceDetailList(data: object): ISetDetailListAction {
  return {
    type: 'SET_SOURCE_DETAIL_LIST',
    data,
  };
}
export function setProjectDetailList(data: object): ISetDetailListAction {
  return {
    type: 'SET_PROJECT_DETAIL_LIST',
    data,
  };
}
export function setLessonPlanDetail(
  data: object
): IFetchLessonPlanDetailSuccessAction {
  return {
    type: 'SET_LESSON_PLAN_DETAIL',
    data,
  };
}
export function setSelectedLessonPlan(id: string): ISetSelectedLessonPlan {
  return {
    type: 'SET_SELECTED_LESSON_PLAN',
    id,
  };
}
export function setSelectedLearningActivity(
  data: object
): ISetSelectedLearningActivityAction {
  return {
    type: 'SET_SELECTED_LEARNING_ACTIVITY',
    data,
  };
}
export function clearLessonPlanDetail() {
  return {
    type: 'CLEAR_LESSON_PLAN_DETAIL',
  };
}
export function clearLessonPlanList() {
  return {
    type: 'CLEAR_LESSON_PLAN_LIST',
  };
}
export function clearSelectedLessonPlan() {
  return {
    type: 'CLEAR_SELECTED_LESSON_PLAN',
  };
}
export function addSourceDetail(topic): ISetTopicDetailAction {
  return {
    type: 'ADD_SOURCE_DETAIL',
    topic,
  };
}
export function deleteSourceDetail(index): IDeleteDetailAction {
  return {
    type: 'DELETE_SOURCE_DETAIL',
    index,
  };
}
export function addProjectDetail(topic): ISetTopicDetailAction {
  return {
    type: 'ADD_PROJECT_DETAIL',
    topic,
  };
}
export function deleteProjectDetail(index): IDeleteDetailAction {
  return {
    type: 'DELETE_PROJECT_DETAIL',
    index,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_LESSON_PLAN',
  };
}
export function resetFormLessonPlan() {
  return {
    type: 'RESET_FORM_LESSON_PLAN_DETAIL',
  };
}
export function fetchLessonPlanFinished(
  data: object
): IFetchLessonPlanDetailSuccessAction {
  return {
    type: 'FETCH_LESSON_PLAN_SCHOOL_FINISHED',
    data,
  };
}
export function resetFormLessonPlanWeek() {
  return {
    type: 'RESET_FORM_LESSON_PLAN_WEEK',
  };
}
