import { Action } from 'redux';
export interface IFetchCurriculumDetailSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedCurriculumAction extends Action {
  id: string;
}
export function fetchCurriculumDetailRequested(): Action {
  return {
    type: 'FETCH_CURRICULUM_DETAIL_REQUESTED',
  };
}
export function setSelectedCurriculum(id): ISetSelectedCurriculumAction {
  return {
    type: 'SET_SELECTED_CURRICULUM',
    id,
  };
}
export function fetchCurriculumDetailFinished(
  data: object
): IFetchCurriculumDetailSuccessAction {
  return {
    type: 'FETCH_CURRICULUM_DETAIL_FINISHED',
    data,
  };
}
