import {
  IFetchClassroomListSuccessAction,
  ISetSelectedClassroomAction,
} from './ClassroomAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedClassroom: '',
  selectedClassroomData: {},
};
export default function ClassroomReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CLASSROOM_LIST_FINISHED': {
      const fetchClassroomListSuccessAction = action as IFetchClassroomListSuccessAction;
      newState.list = fetchClassroomListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_CLASSROOM': {
      const setSelectedClassroomAction = action as ISetSelectedClassroomAction;
      newState.selectedClassroom = setSelectedClassroomAction.id;
      newState.selectedClassroomData = setSelectedClassroomAction.data;
      return { ...newState };
    }
    case 'CLEAR_CLASSROOM_DETAIL': {
      newState.selectedClassroomData = {};
      newState.selectedClassroom = '';
      return { ...newState };
    }
  }
  return state;
}
