import { all, fork } from 'redux-saga/effects';
import {
  changePasswordAction,
  checkUrlResetPasswordAction,
  loginAction,
  renewTokenAction,
  resetPssswordAction,
  setResetPssswordAction,
} from '../../Auth/Saga/AuthSaga';
import {
  getLessonPlanDetailAction,
  submitLessonPlanAction,
} from '../../LessonPlan/Saga/LessonPlanSaga';
import {
  getLessonPlanSchoolAction,
  getSchoolDetailAction,
  getSchoolListAction,
  getSchoolScoringCriteriaAction,
} from '../../School/Saga/SchoolSaga';
import {
  getScoringDetailAction,
  showReportCardAction,
  submitScoringAction,
} from '../../Scoring/Saga/ScoringSaga';
import {
  getSignatureAction,
  submitSignatureAction,
} from '../../Signature/Saga/SignatureSaga';

import { getActivePeriodicYearAction } from '../../PeriodicYear/Saga/PeriodicYearSaga';
import { getClassroomListAction } from '../../Classroom/Saga/ClassroomSaga';
import { getConfigListAction } from '../../Config/Saga/ConfigSaga';
import { getCurriculumDetailAction } from '../../Curriculum/Saga/CurriculumSaga';
import { getGradeListAction } from '../../Grade/Saga/GradeSaga';
import { getStudentListAction } from '../../Student/Saga/StudentSaga';
import { getTrimesterSettingAction } from '../../TrimesterSetting/Saga/TrimesterSettingSaga';
import { handleErrorAction } from '../../App/Saga/AppSaga';

export default function* () {
  yield all([
    fork(loginAction),
    fork(renewTokenAction),
    fork(changePasswordAction),
    fork(resetPssswordAction),
    fork(checkUrlResetPasswordAction),
    fork(setResetPssswordAction),

    fork(getConfigListAction),

    fork(handleErrorAction),

    fork(getActivePeriodicYearAction),

    fork(getSchoolListAction),
    fork(getSchoolDetailAction),
    fork(getSchoolScoringCriteriaAction),
    fork(getLessonPlanSchoolAction),

    fork(getGradeListAction),

    fork(getClassroomListAction),

    fork(getStudentListAction),

    fork(getCurriculumDetailAction),

    fork(getLessonPlanDetailAction),
    fork(submitLessonPlanAction),

    fork(getSignatureAction),
    fork(submitSignatureAction),

    fork(getTrimesterSettingAction),

    fork(getScoringDetailAction),
    fork(submitScoringAction),
    fork(showReportCardAction),
  ]);
}
