import { createSelector } from 'reselect';

const selectorScoringList = state => state.Scoring;

// SELECTOR OTHER
export const scoringSelectedSelector = () =>
  createSelector(selectorScoringList, state => state.selectedScoring);
export const scoringListSelector = () =>
  createSelector(selectorScoringList, state => state.list);
export const scoringDetailSelector = () =>
  createSelector(selectorScoringList, state => state.selectedScoringData);
