import * as Selectors from '../Modules/Auth/Selector/AuthSelector';

import React from 'react';
import Sentry from '../App/Sentry';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from './History';

export default function AuthMiddleware(ComposedComponent) {
  const returnData = props => {
    const { isAuthenticated, redirect, authUser } = props;

    if (!isAuthenticated) {
      redirect();
    }

    Sentry.setUser({
      ...authUser,
      id: authUser.username,
    });
    return (
      <div>{isAuthenticated ? <ComposedComponent {...props} /> : null}</div>
    );
  };

  const mapStateToProps = createStructuredSelector({
    isAuthenticated: Selectors.tokenSelector(),
    authUser: Selectors.authUserSelector(),
  });
  const mapDispatchToProps = dispatch => ({
    redirect: () => history.push('/login'),
  });
  return connect(mapStateToProps, mapDispatchToProps)(returnData);
}
