import { Button, Col, Form, Input, Layout, Row } from 'maveth';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import React from 'react';
import { UnlockOutlined } from '@ant-design/icons';
import backgroundImage from '../../../Assets/Images/backgroundlogin.jpg';
import validate from '../Validation/SetResetPasswordValidation';

interface IProps {
  submitResetPassword;
  appName;
  isLoading;
  intl;
  theme;
  handleForgotPassword;
  errorFormValue;
}

const InputPassword = props => {
  const { input, placeholder } = props;

  return (
    <Form.Item>
      <Input.Password placeholder={placeholder} {...input} />
    </Form.Item>
  );
};

function FormResetPasswordComponent(props: IProps & InjectedFormProps) {
  const { Content } = Layout;
  const {
    submitResetPassword,
    appName,
    isLoading,
    invalid,
    intl,
    theme,
    errorFormValue,
    anyTouched,
  } = props;
  const { sixthBackground, thirdColor } = theme;

  const submitForm = (e: any) => {
    submitResetPassword();
  };
  return (
    <>
      <Content className="backgroundLoginComponent">
        <Row className="rowLogin">
          <Col
            span={12}
            className="colLoginLeft"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <p className="titleLogin">
              {intl.formatMessage({ id: 'welcomeTo' })}
              <span className="appNameLogin"> {appName}</span>
            </p>
            <p className="descriptionLogin">
              {intl.formatMessage({ id: 'taglineLogin' })}
            </p>
            <p className="copyrightLogin">
              Copyright © {new Date().getFullYear()}
            </p>
          </Col>
          <Col
            span={12}
            className="colLoginRight"
            style={{ background: sixthBackground, color: thirdColor }}
          >
            <div className="divFormLogin">
              <h1 className="titleFormLogin" style={{ color: thirdColor }}>
                {intl.formatMessage({ id: 'titleResetPassword' })}
              </h1>
              <p className="descriptionFormLogin">
                {intl.formatMessage({ id: 'descriptionResetPassword' })}
              </p>
              <CAlertErrorMessage
                errorDataForm={errorFormValue}
                isShow={anyTouched && Object.keys(errorFormValue).length > 0}
              />
              <Form className="formLogin" onFinish={submitForm}>
                <Field
                  name="password"
                  component={InputPassword}
                  placeholder={intl.formatMessage({ id: 'password' })}
                />
                <Field
                  name="confirmPassword"
                  component={InputPassword}
                  placeholder={intl.formatMessage({ id: 'confirmPassword' })}
                />
                <Form.Item>
                  <Button
                    type="primary"
                    className="btnLogin"
                    htmlType="submit"
                    icon={<UnlockOutlined />}
                    loading={isLoading}
                    disabled={invalid}
                  >
                    {intl.formatMessage({ id: 'resetPassword' })}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
}

const ResetPasswordComponent = reduxForm({
  form: 'setResetPasswordForm',
  validate,
})(FormResetPasswordComponent);
export default ResetPasswordComponent;
