import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import { InputRich } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/FormLessonPlanAddValidation';

function FormModalFormLessonPlanComponent(props) {
  const {
    anyTouched,
    formLessonPlanModalIsShow,
    errorFormValue,
    handleCancel,
    handleSubmit,
    intl,
    loading,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="formLessonPlanForm" />
      </>
    );
  };

  const cardContent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="mainTheme"
              component={InputRich}
              placeholder={intl.formatMessage({
                id: `placeholderMainTheme`,
              })}
              idComponent="inputMainTheme"
              label={intl.formatMessage({
                id: 'mainThemeTopic',
              })}
            />
            <Field
              name="learningactivities"
              component={InputRich}
              placeholder={intl.formatMessage({
                id: 'placeholderLearningActivity',
              })}
              idComponent="inputLearningActivuty"
              label={intl.formatMessage({
                id: 'learningActivity',
              })}
            />
            <Field
              name="resource"
              component={InputRich}
              placeholder={intl.formatMessage({
                id: 'placeHolderSource',
              })}
              idComponent="inputSourceTitle"
              label={intl.formatMessage({
                id: 'inputSourceName',
              })}
            />
            <Field
              name="project"
              component={InputRich}
              placeholder={intl.formatMessage({
                id: 'placeHolderProjectTitle',
              })}
              idComponent="inputProjectTitle"
              label={intl.formatMessage({
                id: 'inputProjectName',
              })}
            />
          </Col>
        </Row>
      </>
    );
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={errorFormValue && Object.keys(errorFormValue).length > 0}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  return (
    <Modal
      isShow={formLessonPlanModalIsShow}
      header={headerComponent()}
      content={cardContent()}
      footer={footerComponent()}
      handleClose={handleCancel}
      handleSubmit={handleSubmit}
      isLoading={loading}
      formLayout="vertical"
      isForm={true}
    />
  );
}

const ModalFormLessonPlanComponent = reduxForm({
  form: 'lessonPlanForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalFormLessonPlanComponent);
export default ModalFormLessonPlanComponent;
