import { Action } from 'redux';
export interface ISetSchoolDetailAction extends Action {
  data: object;
}
export interface IFetchSchoolListSuccessAction extends Action {
  data: object;
}
export interface IFetchSchoolDataSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedSchoolAction extends Action {
  id: string;
}
export interface ISearchSchoolAction extends Action {
  name: string;
}
export interface ILessonPlanSchool extends Action {
  idschool: string;
  week: number;
}
export function fetchLessonPlanSchoolRequested(
  idschool,
  week
): ILessonPlanSchool {
  return {
    type: 'FETCH_LESSON_PLAN_SCHOOL_REQUESTED',
    idschool,
    week,
  };
}
export function fetchSchoolListRequested(): Action {
  return {
    type: 'FETCH_SCHOOL_LIST_REQUESTED',
  };
}
export function fetchSchoolScoringCriteriaRequested(): Action {
  return {
    type: 'FETCH_SCHOOL_SCORING_CRITERIA_REQUESTED',
  };
}
export function fetchSchoolDetailRequested(): Action {
  return {
    type: 'FETCH_SCHOOL_DETAIL_REQUESTED',
  };
}
export function clearSchoolDetail(): Action {
  return {
    type: 'CLEAR_SCHOOL_DETAIL',
  };
}
export function setSelectedSchool(id: string): ISetSelectedSchoolAction {
  return {
    type: 'SET_SELECTED_SCHOOL',
    id,
  };
}
export function searchSchool(name: string): ISearchSchoolAction {
  return {
    type: 'SEARCH_SCHOOL',
    name,
  };
}
export function fetchSchoolListFinished(
  data: object
): IFetchSchoolListSuccessAction {
  return {
    type: 'FETCH_SCHOOL_LIST_FINISHED',
    data,
  };
}
export function fetchSchoolScoringCriteriaFinished(
  data: object
): IFetchSchoolListSuccessAction {
  return {
    type: 'FETCH_SCHOOL_SCORING_CRITERIA_FINISHED',
    data,
  };
}
export function fetchSchoolDetailFinished(
  data: object
): IFetchSchoolDataSuccessAction {
  return {
    type: 'FETCH_SCHOOL_DETAIL_FINISHED',
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_SCHOOL',
  };
}
