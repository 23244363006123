import { createSelector } from 'reselect';

const selectorSchoolList = state => state.School;
const selectorFormLessonPlanSchool = state => state.form.lessonPlanWeek.values;

// SELECTOR OTHER
export const schoolListSelector = () =>
  createSelector(selectorSchoolList, state => state.list);
export const schoolScoringCriteriaSelector = () =>
  createSelector(
    selectorSchoolList,
    state => state.selectedSchoolScoringCriteria
  );
export const schoolSelectedSelector = () =>
  createSelector(selectorSchoolList, state => state.selectedSchool);
export const schoolDetailSelector = () =>
  createSelector(selectorSchoolList, state => state.selectedSchoolData);
export const schoolModalActionSelector = () =>
  createSelector(selectorSchoolList, state => state.schoolModalAction);
export const selectedSchoolNameSelector = () =>
  createSelector(selectorSchoolList, state => state?.selectedSchoolData?.name);
export const selectedWeekSelector = () =>
  createSelector(selectorFormLessonPlanSchool, state => state.week);
