import * as ActionClassroom from '../../Classroom/Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../../Curriculum/Store/CurriculumAction';
import * as ActionGrade from '../Store/GradeAction';
import * as ActionSchool from '../../School/Store/SchoolAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';

import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import ModalGradeComponent from '../Component/ModalGradeComponent';
import { PartitionOutlined } from '@ant-design/icons';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function ModalGradeContainer(props) {
  const { componentAction, gradeAction, intl, schoolAction, classroomAction } =
    props;

  const handleCancel = () => {
    componentAction.openModal('SchoolGrade');
    schoolAction.clearSchoolDetail();
  };
  const listAction = row => {
    const classroomClick = () => {
      gradeAction.setSelectedGrade(row.id, row);
      classroomAction.fetchClassroomListRequested();
      componentAction.openModal('SchoolClassroom');
    };

    const buttonData = [
      {
        type: 'warning',
        icon: <PartitionOutlined />,
        className: 'btnActionDetail',
        onClick: classroomClick,
        tooltipTitle: intl.formatMessage({ id: 'classroom' }),
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  return (
    <>
      <ModalGradeComponent
        handleCancel={handleCancel}
        listAction={listAction}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  gradeModalIsShow: SelectorsComponent.modalIsShow('SchoolGrade'),
  gradeList: SelectorsGrade.gradeListSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalGradeContainer));
