import { Action } from 'redux';

export interface IFetchSignatureSuccessAction extends Action {
  data: object;
}
export interface ISetSignatureFormAction extends Action {
  data: string;
}

export function submitSignatureRequested(): Action {
  return {
    type: 'SUBMIT_SIGNATURE_REQUESTED',
  };
}
export function fetchSignatureRequested(): Action {
  return {
    type: 'FETCH_SIGNATURE_REQUESTED',
  };
}
export function fetchActiveSignatureFinished(
  data: object
): IFetchSignatureSuccessAction {
  return {
    type: 'FETCH_SIGNATURE_FINISHED',
    data,
  };
}
export function setESignDataForm(data: string): ISetSignatureFormAction {
  return {
    type: 'SET_SIGNATURE_DATA_FORM',
    data,
  };
}
export function clearESignDataForm() {
  return {
    type: 'CLEAR_SIGNATURE_DATA_FORM',
  };
}
