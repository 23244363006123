const ScoringValidation = (values, idstudent, scoringList, intl) => {
  const errors: any = {};

  const validationLang = intl.messages;
  if (scoringList.scoring) {
    for (const iterator of scoringList.scoring) {
      if (values[`${iterator}__${idstudent}`] === undefined) {
        errors[`${iterator}__${idstudent}`] = validationLang.required;
      }
    }
  }
  return errors;
};
export default ScoringValidation;
