import { Alert, Col, Collapse, Descriptions, Empty, Modal, Row } from 'maveth';
import {
  CheckOutlined,
  CommentOutlined,
  EditOutlined,
  HourglassOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../Assets/Components/CButton';
import { FormattedMessage } from 'react-intl';
import { InputSelect } from '../../../Assets/Components/CInput';
import ModalFormLessonPlanContainer from '../Container/ModalFormLessonPlanContainer';
import React from 'react';
import parse from 'html-react-parser';

function FormModalLessonPlanComponent(props) {
  const {
    lessonPlanModalIsShow,
    handleClose,
    listMonth,
    intl,
    loading,
    handleLessonPlan,
    listLessonPlan,
  } = props;

  const { Panel } = Collapse;

  const headerComponent = () => {
    return <FormattedMessage id="lessonPlanForm" />;
  };

  const contentComponent = () => {
    return (
      <>
        <Row>
          <Col span={12}>
            <Field
              name="week"
              component={InputSelect}
              data={listMonth}
              idComponent="inputWeek"
              dafaultItemName={intl.formatMessage({
                id: 'selectPeriod',
              })}
              label={intl.formatMessage({
                id: 'period',
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {listLessonPlan.length === 0 ? <Empty /> : null}
            {listLessonPlan.map((val, idx) => {
              const { name, curriculumdata } = val;
              const {
                yearlylearningobjective,
                maintheme,
                trimesterlearningobjective,
                lessonplan,
                topicofdiscussion,
              } = curriculumdata;
              const {
                learningactivities,
                resource,
                project,
                commentresource,
                commentproject,
              } = lessonplan || {};

              const buttonData = [
                {
                  type: 'primary',
                  icon: val.lessonplan ? <EditOutlined /> : <PlusOutlined />,
                  className: 'btnActionDetail',
                  onClick: () =>
                    handleLessonPlan(
                      lessonplan ? 'update' : 'register',
                      lessonplan?.id,
                      curriculumdata
                    ),
                  id: `buttonActionLessonPlan-${idx}`,
                  content: intl.formatMessage({
                    id: val.lessonplan ? 'editLessonPlan' : 'addLessonPlan',
                  }),
                },
              ];
              return (
                <Collapse
                  expandIconPosition="right"
                  bordered={false}
                  key={`lessonPlanByCurriculum${idx}`}
                >
                  <Panel
                    header={
                      lessonplan?.status === 'approved' ? (
                        <>
                          <CheckOutlined /> {name}
                        </>
                      ) : (
                        <>
                          <HourglassOutlined /> {name}
                        </>
                      )
                    }
                    key={`lessonPlan${idx}`}
                    className={`timelineLessonPlan ${
                      lessonplan
                        ? 'timelineLessonPlanSuccess'
                        : 'timelineLessonPlanWarning'
                    }`}
                  >
                    <div className="divContainerDescItem">
                      <Descriptions
                        layout="vertical"
                        bordered
                        style={{ marginBottom: 30 }}
                        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                      >
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'yearlyLearningObjective',
                          })}
                        >
                          {parse(yearlylearningobjective)}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'trimesterTopic',
                          })}
                        >
                          {trimesterlearningobjective
                            ? parse(trimesterlearningobjective)
                            : null}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'mainThemeTopic',
                          })}
                        >
                          {maintheme ? parse(maintheme) : null}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'topicOfDiscussionTopic',
                          })}
                        >
                          {topicofdiscussion ? parse(topicofdiscussion) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                          span={2}
                          label={intl.formatMessage({
                            id: 'learningActivity',
                          })}
                        >
                          {learningactivities ? parse(learningactivities) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'resource',
                          })}
                        >
                          {resource ? parse(resource) : '-'}
                          {commentresource && lessonplan.status === 'review' ? (
                            <Alert
                              message={commentresource}
                              type="warning"
                              showIcon
                              icon={<CommentOutlined />}
                              style={{ width: '100%' }}
                            />
                          ) : null}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={intl.formatMessage({
                            id: 'project',
                          })}
                        >
                          {project ? parse(project) : '-'}
                          {commentproject && lessonplan.status === 'review' ? (
                            <Alert
                              message={commentproject}
                              type="warning"
                              showIcon
                              icon={<CommentOutlined />}
                              style={{ width: '100%' }}
                            />
                          ) : null}
                        </Descriptions.Item>
                      </Descriptions>
                      <CButton
                        buttonData={buttonData}
                        containerStyle={{ marginBottom: 0 }}
                      />
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Modal
        isShow={lessonPlanModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        handleClose={handleClose}
        isLoading={loading}
      />
      <ModalFormLessonPlanContainer />
    </>
  );
}

const ModalLessonPlanComponent = reduxForm({
  form: 'lessonPlanWeek',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
})(FormModalLessonPlanComponent);
export default ModalLessonPlanComponent;
