import {
  IFetchSchoolDataSuccessAction,
  IFetchSchoolListSuccessAction,
  ISearchSchoolAction,
  ISetSelectedSchoolAction,
} from './SchoolAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  originaList: [],
  selectedSchoolScoringCriteria: [],
  selectedSchool: '',
  selectedSchoolData: {},
  schoolModalAction: 'register',
};
export default function SchoolReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SCHOOL_LIST_FINISHED': {
      const fetchSchoolListSuccessAction =
        action as IFetchSchoolListSuccessAction;
      newState.list = fetchSchoolListSuccessAction.data;
      newState.originaList = fetchSchoolListSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_SCHOOL_DETAIL_FINISHED': {
      const fetchSchoolDataSuccessAction =
        action as IFetchSchoolDataSuccessAction;
      newState.selectedSchoolData = fetchSchoolDataSuccessAction.data;
      return { ...newState };
    }
    case 'FETCH_SCHOOL_SCORING_CRITERIA_FINISHED': {
      const fetchSchoolDataSuccessAction =
        action as IFetchSchoolDataSuccessAction;
      newState.selectedSchoolScoringCriteria =
        fetchSchoolDataSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_SCHOOL': {
      const setSelectedSchoolAction = action as ISetSelectedSchoolAction;
      newState.selectedSchool = setSelectedSchoolAction.id;
      return { ...newState };
    }
    case 'CLEAR_SCHOOL_DETAIL': {
      newState.selectedSchoolData = {};
      newState.selectedSchool = '';
      return { ...newState };
    }
    case 'SEARCH_SCHOOL': {
      const searchSchoolAction = action as ISearchSchoolAction;
      const searchName = searchSchoolAction.name?.toLowerCase();
      let list = [...state.list];
      if (!searchName || searchName === '') {
        list = [...state.originaList];
      } else {
        list = list.filter((item: any) => {
          const name = item.name?.toLowerCase();
          return name.indexOf(searchName) > -1;
        });
      }
      newState.list = list;
      return { ...newState };
    }
  }
  return state;
}
