import * as React from 'react';

import hoistNonReactStatics from 'hoist-non-react-statics';

const withTemplate = (Template, WrappedComponent) => {
  const AddedTemplate = props => {
    return (
      <Template {...props}>
        <WrappedComponent {...props} />
      </Template>
    );
  };

  return hoistNonReactStatics(AddedTemplate, WrappedComponent);
};

export default withTemplate;
