import * as ActionApp from '../../App/Store/AppAction';
import * as ActionLessonPlan from '../../LessonPlan/Store/LessonPlanAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorSchool from '../Selector/SchoolSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getSchoolListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/school`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getLessonPlanSchoolProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { idschool, week } = action;
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/school/${idschool}/lessonplan/week/${week}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.fetchLessonPlanFinished(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getSchoolDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const id = yield select(SelectorSchool.schoolSelectedSelector());
    const { data } = yield call(axios.get, `${API_URL}/school/${id}`, config);
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolDetailFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getSchoolScoringCriteriaProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    yield put(ActionsComponent.processLoading(true));
    const id = yield select(SelectorSchool.schoolSelectedSelector());
    const { data } = yield call(
      axios.get,
      `${API_URL}/school/scoringcriteria/${id}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionSchool.fetchSchoolScoringCriteriaFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getSchoolListAction() {
  yield takeLatest('FETCH_SCHOOL_LIST_REQUESTED', getSchoolListProcess);
}
export function* getSchoolDetailAction() {
  yield takeLatest('FETCH_SCHOOL_DETAIL_REQUESTED', getSchoolDetailProcess);
}
export function* getSchoolScoringCriteriaAction() {
  yield takeLatest(
    'FETCH_SCHOOL_SCORING_CRITERIA_REQUESTED',
    getSchoolScoringCriteriaProcess
  );
}
export function* getLessonPlanSchoolAction() {
  yield takeLatest(
    'FETCH_LESSON_PLAN_SCHOOL_REQUESTED',
    getLessonPlanSchoolProcess
  );
}
