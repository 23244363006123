import * as ActionClassroom from '../Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionStudent from '../../Student/Store/StudentAction';
import * as SelectorsClassroom from '../Selector/ClassroomSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';

import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import ModalClassroomComponent from '../Component/ModalClassroomComponent';
import React from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function ModalClassroomContainer(props) {
  const { componentAction, intl, gradeAction, classroomAction, studentAction } =
    props;

  const handleCancel = () => {
    componentAction.openModal('SchoolClassroom');
    gradeAction.clearGradeDetail();
  };
  const listAction = row => {
    const studentClick = () => {
      classroomAction.setSelectedClassroom(row.id, row);
      studentAction.fetchStudentListRequested();
      componentAction.openModal('SchoolStudent');
    };

    const buttonData = [
      {
        type: 'warning',
        icon: <TeamOutlined />,
        className: 'btnActionDetail',
        onClick: studentClick,
        tooltipTitle: intl.formatMessage({ id: 'student' }),
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };
  return (
    <>
      <ModalClassroomComponent
        handleCancel={handleCancel}
        listAction={listAction}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  classroomModalIsShow: SelectorsComponent.modalIsShow('SchoolClassroom'),
  classroomList: SelectorsClassroom.classroomListSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  selectedGradeName: SelectorsGrade.selectedGradeNameSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalClassroomContainer));
