import { Action } from 'redux';

export interface IApplyTheme extends Action {
  dark: boolean;
}
export interface IChangeTheme extends Action {
  theme: any;
}

export const changeTheme = (theme: string): IChangeTheme => {
  return {
    type: 'CHANGE_THEME',
    theme,
  };
};
