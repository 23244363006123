import * as ActionApp from '../../App/Store/AppAction';
import * as ActionTrimesterSetting from '../Store/TrimesterSettingAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getTrimesterSettingProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { data } = yield call(
      axios.get,
      `${API_URL}/trimestersetting/current`,
      config
    );
    if (data && data !== '') {
      yield put(
        ActionTrimesterSetting.fetchActiveTrimesterSettingFinished(data)
      );
    }
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getTrimesterSettingAction() {
  yield takeLatest(
    'FETCH_TRIMESTER_SETTING_REQUESTED',
    getTrimesterSettingProcess
  );
}
