import * as ActionApp from '../../App/Store/AppAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionSignature from '../Store/SignatureAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorSignature from '../Selector/SignatureSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getSignatureProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { data } = yield call(axios.get, `${API_URL}/signature`, config);
    yield put(ActionSignature.fetchActiveSignatureFinished(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitSignatureProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const dataBase64 = yield select(SelectorSignature.signatureFormSelector());

    const data = dataBase64.split(',')[1];
    yield put(ActionComponent.processLoading(false));
    yield call(
      axios.post,
      `${API_URL}/signature`,
      {
        data,
      },
      config
    );
    yield put(ActionComponent.openModal('ESign'));
    yield put(ActionSignature.clearESignDataForm());
    yield put(ActionSignature.fetchSignatureRequested());
    yield put(ActionComponent.processLoading(false));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successSubmitEsign" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getSignatureAction() {
  yield takeLatest('FETCH_SIGNATURE_REQUESTED', getSignatureProcess);
}
export function* submitSignatureAction() {
  yield takeLatest('SUBMIT_SIGNATURE_REQUESTED', submitSignatureProcess);
}
