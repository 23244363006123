import {
  IFetchSignatureSuccessAction,
  ISetSignatureFormAction,
} from './SignatureAction';

import { Action } from 'redux';

export const initialState: any = {
  signatureData: null,
  signatureDataForm: null,
};
export default function SignatureReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SIGNATURE_FINISHED': {
      const fetchSignatureSuccessAction = action as IFetchSignatureSuccessAction;
      newState.signatureData = fetchSignatureSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SIGNATURE_DATA_FORM': {
      const setSignatureFormAction = action as ISetSignatureFormAction;
      newState.signatureDataForm = setSignatureFormAction.data;
      return { ...newState };
    }
    case 'CLEAR_SIGNATURE_DATA_FORM': {
      newState.signatureDataForm = null;
      return { ...newState };
    }
  }
  return state;
}
