import React from 'react';
import { store } from './../../App/ConfigureStore';

export const ToastConfirm = (onOk, onCancel, text) => {
  const state = store.getState();
  const theme = state.Theme.theme;
  const { fifthBackground, thirdColor } = theme;

  return {
    onOk,
    onCancel,
    component: () => (
      <div
        style={{
          background: fifthBackground,
          color: thirdColor,
          minHeight: '30px',
          textAlign: 'center',
          padding: '20px',
        }}
      >
        <span>{text}</span>
      </div>
    ),
  };
};
