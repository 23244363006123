export const localeUppy: any = {};

export default {
  action: 'Action',
  add: 'Add',
  addConfig: 'Add Config',
  addPeriodicYear: 'Add periodic year',
  addRole: 'Add Role',
  addSchool: 'Add school',
  addTeacher: 'Add teacher',
  addUser: 'Add User',
  address: 'Address',
  amount: 'Amount',
  by: 'By',
  cancel: 'cancel',
  category: 'Category',
  changePassword: 'Change Password',
  changePasswordDrawerLabel: 'Change password for your account',
  changePasswordSuccess: 'Success change password! Please re-login',
  changePasswordUser: 'Change password user',
  classroom: 'Classroom',
  classroomForm: 'Classroom Form',
  classroomList: 'Classroom list',
  config: 'Config',
  configForm: 'Config Form',
  configList: 'Config List',
  confirmPassword: 'Confirm password',
  confirmQuestion: 'Are you sure',
  confirmationDelete: 'Are you sure to delete',
  confirmationFinish: 'Are you sure to finish',
  create: 'Create',
  createNewClassroom: 'Create new classroom',
  createNewGrade: 'Create new grade',
  createNewStudent: 'Import student list',
  createRoleSuccess: 'Success add role',
  dashboard: 'Dashboard',
  delete: 'Delete',
  deleteClassroomSuccess: 'Success delete classroom!',
  deleteConfigSuccess: 'Success delete config',
  deleteConfirmDeveloper: 'Delete. Nb: Ask developer if you doubt',
  deleteGradeSuccess: 'Success delete grade!',
  deleteRoleSuccess: 'Success delete role',
  deleteStudentSuccess: 'Success delete student!',
  deleteUserSuccess: 'Success delete user',
  description: 'Description',
  descriptionLogin: 'Enter your username and password!',
  detail: 'Detail',
  dontHavePermission: `You dont have permission to access this page!`,
  download: 'Download',
  downloadTemplateStudentDesc:
    'Download a template to help you format your excel to properly list student data',
  edit: 'Edit',
  email: 'Email',
  entires: 'data',
  errorAlertTitle: 'Error list',
  finish: 'finish',
  forbidden: 'You not have access to this feature! Please contact admin',
  generate: 'Generate',
  goToPage: 'Go to page',
  grade: 'Grade',
  gradeForm: 'Grade Form',
  gradeList: 'Grade list',
  history: 'History',
  inputDate: 'Input Date',
  inputImportStudent: 'Student List',
  inputSchoolLogo: 'School logo',
  lastUpdate: 'Last update',
  lastUpdateBy: 'Last update by',
  list: 'List',
  login: 'Login',
  loginSuccess: 'Login success',
  logoutConfirmation: 'Are you sure to log out?',
  moduleList: 'Module List',
  modulePermission: 'Module Permission',
  name: 'Name',
  nationality: 'Nationality',
  networkError: 'Network error! Please check your connection!',
  notes: 'Notes',
  of: 'of',
  otherContact: 'Other contact',
  page: 'Page',
  password: 'Password',
  period: 'Period',
  periodicYear: 'Periodic year',
  periodicYearForm: 'Periodic Year Form',
  periodicYearList: 'Periodic year list',
  phone: 'Phone',
  placeholderAddress: 'Input address here',
  placeholderAmount: 'Input amount here',
  placeholderCode: 'Input product code here',
  placeholderConfirmPassword: 'Input confirm password here',
  placeholderEmail: 'Input email here',
  placeholderName: 'Input name here',
  placeholderNotes: 'Input notes here',
  placeholderOtherContact: 'Input other contact here',
  placeholderPassword: 'Input password here',
  placeholderPhone: 'Input phone here',
  placeholderQty: 'Input qty here',
  placeholderType: 'Input type here',
  placeholderUsername: 'Input username here',
  placeholderValue: 'Input value here',
  qty: 'Qty',
  read: 'Read',
  reason: 'Reason',
  record: 'record',
  refresh: 'Refresh',
  registerClassroomSuccess: 'Success add new classroom!',
  registerConfigSuccess: 'Success add config',
  registerGradeSuccess: 'Success add new grade!',
  registerSchoolSuccess: 'Success add school',
  registerStudentSuccess: 'Success add new student!',
  registerUserSuccess: 'Success add user',
  reset: 'Reset',
  role: 'Role',
  roleForm: 'Role Form',
  roleList: 'Role List',
  school: 'School',
  schoolForm: 'Form School',
  schoolList: 'School List ',
  search: 'Search',
  sessionExpired:
    'Authorization failed. Please login again or check your username password',
  signMeOut: 'Sign me out',
  status: 'Status',
  student: 'Student',
  studentForm: 'Student Form',
  studentList: 'Student list',
  submit: 'Submit',
  taglineLogin: 'Odysee teacher portal',
  teacher: 'Teacher',
  teacherForm: 'Teacher Form',
  teacherList: 'Teacher List',
  titleLogin: 'Login Account',
  today: 'Today',
  type: 'Type',
  unknownError: 'Unkown error, please contact administrator!',
  update: 'Update',
  updateClassroomSuccess: 'Success update classroom!',
  updateConfigSuccess: 'Success update config',
  updateGradeSuccess: 'Success update grade!',
  updatePasswordUserSuccess: 'Success change password user!',
  updateRoleSuccess: 'Success update role',
  updateSchoolSuccess: 'Success update school',
  updateStudentSuccess: 'Success update student!',
  updateUserSuccess: 'Success update user',
  user: 'User',
  userChangePasswordForm: 'Change Password Form',
  userForm: 'User Form',
  userList: 'User List',
  userProfile: 'User Profile',
  username: 'Username',
  value: 'Value',
  welcomeTo: 'Welcome to',
  yearEnd: 'Year end',
  yearStart: 'Year start',
  yearend: 'Year end',
  yearstart: 'Year start',
  changePeriodicYearStatusSuccess: 'Success change periodic year status!',
  deletePeriodicYearSuccess: 'Success delete periodic year!',
  registerPeriodicYearSuccess: 'Success add periodic year!',
  forgotPasswordLabel: 'Forgot password?',
  userResetPasswordForm: 'Forgot Password Form',
  successSendResetPasswordLink:
    'Success send reset password link. Please check your email!',
  titleResetPassword: 'Reset Password',
  descriptionResetPassword: 'Enter your new password here!',
  resetPassword: 'Reset password',
  addGrade: 'Add Grade',
  addClassroom: 'Add Classroom',
  selectTeacher: 'Select teacher',
  successAssignTeacher: 'Success assign teacher!',
  addCurriculum: 'Add Curriculum',
  curriculumForm: 'Curriculum Form',
  curriculum: 'Curriculum',
  yearlyLearningObjective: 'Yearly learning objective',
  placeholderYearlyLearningObjective: 'Input yearly learning objective',
  trimesterTopic: 'Trimester Topic',
  placeHolderTopic: 'Input topic',
  useTopicOfDiscussion: 'Use topic of discussion',
  topic: 'Topic',
  topicOfDiscussionTopic: 'Topic Of Discussion',
  mainThemeTopic: 'Main Theme',
  validationTopic: 'Topic required! Min 3 character and max 255 character!',
  registerCurriculumSuccess: 'Success register curriculum',
  updateCurriculumSuccess: 'Success update curriculum',
  deleteCurriculumSuccess: 'Success delete curriculum',
  gradeTeacherFormList: 'Grade List Of Teacher',
  assignTeacher: 'Assign teacher',
  selectGrade: 'Select grade',
  successDeleteAssignTeacher: 'Success delete assign teacher',
  placeholderSearchSchoolName: 'Search school name here',
  schoolInfoForm: 'School Info',
  lessonPlan: 'Lesson plan',
  lessonPlanForm: 'Lesson Plan Form',
  learningActivity: 'Learning activity',
  resource: 'Resource',
  project: 'Project',
  addLessonPlan: 'Add lesson plan',
  editLessonPlan: 'Edit lesson plan',
  formLessonPlanForm: 'Lesson Plan Form',
  placeholderLearningActivity: 'Input learning activity name here',
  placeHolderSource: 'Input source here',
  sourceTopic: 'Source',
  placeHolderProjectTitle: 'Input project here',
  projectTopic: 'Project',
  inputSourceName: 'Source title',
  inputProjectName: 'Project title',
  menuList: 'Menu List',
  signature: 'Signature',
  clear: 'Clear',
  save: 'Save',
  signatureNullValidation: 'Signature required!',
  signatureMin: 'Repeat signature and enlarge size!',
  successSubmitEsign: 'Success submit E-Sign',
  currentESign: 'Current E-Sign',
  takeEsignLabel: 'Take e-sign here',
  placeholderMainTheme: 'Input main theme here',
  successSetLessonplan: 'Success set lesson plan',
  scoring: 'Scoring',
  trimester: 'Trimester',
  structure: 'Structure',
  vocab: 'Vocab',
  fluency: 'Fluency',
  required: 'Required!',
  placeholderSearchStudentName: 'Search student name here',
  successScoringStudent: 'Success scoring student',
  printReport: 'Print report',
  comment: 'Comment',
  week: 'Week',
  selectPeriod: 'Select period',
  mid: 'Mid',
  validation: {
    classroomMaxNameLength: 'Maximal length name 50 character',
    classroomMinNameLength: 'Minimal length name 3 character',
    classroomNameRequired: 'Name required!',
    configMaxType: 'Max length config type 50 character!',
    configValueMax: 'Max length config value 50 character!',
    configValueRequired: 'Value required!',
    confirmPasswordNotMatch: 'Confirm password not same with password!',
    emailRequired: 'Email required!',
    gradeMaxNameLength: 'Maximal length name 50 character',
    gradeMinNameLength: 'Minimal length name 3 character',
    gradeNameRequired: 'Name required!',
    modulePermissionRequired: 'Module permission required!',
    nameTeacherRequired: 'Name required!',
    nameUserMaxNameLength: 'Max length name 50 character!',
    nameUserMinNameLength: 'Min length name 5 character!',
    nameUserRequired: 'Name required!',
    notesRequired: 'Notes required!',
    passwordRequired: 'Password required!',
    paswordMaxLength: 'Max length password 50 character!',
    paswordMinLength: 'Min length password 5 character!',
    periodRequired: 'Period required!',
    qtyRequired: 'Quantity required!',
    reasonRequired: 'Reason required!',
    roleMaxNameLength: 'Max length role name 50 character!',
    roleMinNameLength: 'Min length role name 3 character!',
    roleNameRequired: 'Name required!',
    roleRequired: 'Role required!',
    schoolMaxNameLength: 'Maximal length name 50 character',
    schoolMinNameLength: 'Minimal length name 3 character',
    schoolNameRequired: 'Name required!',
    studentImportFileRequired: 'File import required!',
    studentMaxNameLength: 'Maximal length name 50 character',
    studentMinNameLength: 'Minimal length name 3 character',
    studentNameRequired: 'Name required!',
    typeRequired: 'Type required!',
    userInvalidEmailFormat: 'Invalid email address!',
    usernameMaxNameLength: 'Max length username 50 character!',
    usernameMinNameLength: 'Min length username 5 character!',
    usernameRequired: 'Username required!',
    yearEndRequired: 'Year end required!',
    yearStartRequired: 'Year start required!',
    yearlyLearningObjectiveRequired: 'Yearly learning objective required!',
    trimesterObjectiveRequired: 'Trimester objective must 3 topic!',
    mainThemeObjectiveRequired:
      'Main theme objective required! And must 11 topic',
    topicOfDiscussionObjectiveRequired:
      'Topic of discussion objective required! And must 11 topic',
    yearlyLearningObjectiveMin: 'Yearly learning objective min 3 character',
    yearlyLearningObjectiveMax: 'Yearly learning objective max 255 character',
    learningActivityRequired: 'Learning activity required!',
    learningActivityMin: 'Learning activity min 3 character',
    learningActivityMax: 'Learning activity max 255 character',
  },
  'address.maximal.validation': 'Maximal address length is 255 character',
  'cannot.same.prev.password': 'Cannot same with previous password',
  'code.already.exists': 'Code already exist',
  'email.already.taken': 'Email already taken',
  'email.type.must.unique': 'Email already registered',
  'item.have.null.capitalprice': 'Item capital price not set: ',
  'name.type.must.unique': 'Name already exist',
  'notes.maximal.validation': 'Maximal notes length is 255 character',
  'oldpassword.not.match': 'Old password not match with existing',
  'phone.maximal.validation': 'Maximal phone length is 255 character',
  'phone.type.must.unique': 'Phone already registered!',
  'role.has.users': 'Role has user!',
  'role.must.unique': 'Role already exists',
  'username.already.taken': 'Username already taken',
  'yearend.must.next.year': 'Year end must next year!',
  'yearend.type.must.unique': 'Year end already exists!',
  'yearstart.must.current.year': 'Year start must current year!',
  'yearstart.type.must.unique': 'Year start already exists!',
  'scoring.not.found': 'Scoring card not available!',
};

localeUppy.strings = {
  addMoreFiles: 'Add more file',
  addingMoreFiles: 'Add more file',
  allowAccessDescription:
    'To take pictures or record videos with your camera, please allow camera access for this site.',
  allowAccessTitle: 'Please allow access to your camera',
  authenticateWith: 'Conencting to %{pluginName}',
  authenticateWithTitle:
    'Please authentication with %{pluginName} to select file',
  back: 'Back',
  browse: 'search',
  cancel: 'Cancel',
  cancelUpload: 'Cancel upload',
  chooseFiles: 'Select file',
  closeModal: 'Close popup',
  companionAuthError: 'Need authorization',
  companionError: 'Failed connect to Companion',
  complete: 'Complete',
  connectedToInternet: 'Connecting to internet',
  copyLink: 'Copy link',
  copyLinkToClipboardFallback: 'Copy URL below',
  copyLinkToClipboardSuccess: 'Link copied to clipboard',
  creatingAssembly: 'Ready for upload...',
  creatingAssemblyFailed: 'Transloadit: Cannot create Assembly',
  dashboardTitle: 'Uploaded file',
  dashboardWindowTitle: 'File upload section (Press escape to close)',
  dataUploadedOfTotal: '%{complete} of %{total}',
  done: 'Done',
  dropHereOr: 'Drop file here or %{browse}',
  dropHint: 'Drop file here',
  dropPaste: 'Drop file here to upload, or %{browse} file from computer',
  dropPasteImport: 'Drop file here, salin, %{browse} or import from',
  edit: 'Ubah',
  editFile: 'Edit file',
  editing: 'Edit %{file}',
  emptyFolderAdded: 'No file added from empty folder',
  encoding: 'Encoding file...',
  enterCorrectUrl:
    'Incorrect URL: Make sure you enter a direct link to the file',
  enterUrlToImport: 'Enter the URL to import the file',
  exceedsSize: 'This file exceeds the maximum allowable size of',
  failedToFetch: 'Companion failed fetch from this url, please recheck',
  failedToUpload: 'Failed upload %{file}',
  fileSource: 'Source file: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} from %{smart_count} file uploaded',
    '1': '%{complete} from %{smart_count} file uploaded',
    '2': '%{complete} from %{smart_count} file uploaded',
  },
  filter: 'Filter',
  finishEditingFile: 'Finish editing file',
  folderAdded: {
    '0': 'Success add %{smart_count} file from %{folder}',
    '1': 'Success add %{smart_count} file from %{folder}',
    '2': 'Success add %{smart_count} file from %{folder}',
  },
  import: 'Import',
  importFrom: 'Import from %{name}',
  link: 'Link',
  loading: 'Loading...',
  logOut: 'Log out',
  myDevice: 'My device',
  noFilesFound: 'No file found',
  noInternetConnection: 'No internet connection',
  pause: 'Pause',
  pauseUpload: 'Pause upload',
  paused: 'Paused',
  poweredBy: 'Powered by',
  preparingUpload: 'Preparing upload...',
  processingXFiles: {
    '0': 'Processing %{smart_count} file',
    '1': 'Processing %{smart_count} file',
    '2': 'Processing %{smart_count} file',
  },
  removeFile: 'Remove file',
  resetFilter: 'Reset filter',
  resume: 'Resume',
  resumeUpload: 'Continue upload',
  retry: 'Retry',
  retryUpload: 'Retry upload',
  saveChanges: 'Save change',
  selectXFiles: {
    '0': 'Select %{smart_count} file',
    '1': 'Select %{smart_count} file',
    '2': 'Select %{smart_count} file',
  },
  smile: 'Smile!',
  startRecording: 'Start record video',
  stopRecording: 'Stop record video',
  takePicture: 'Pick picture',
  timedOut: 'Upload stop for %{seconds} second, cancel.',
  upload: 'Upload',
  uploadComplete: 'Upload success',
  uploadFailed: 'Failed upload',
  uploadPaused: 'Upload paused',
  uploadXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  uploadXNewFiles: {
    '0': 'Upload +%{smart_count} file',
    '1': 'Upload +%{smart_count} file',
    '2': 'Upload +%{smart_count} file',
  },
  uploading: 'Upload',
  uploadingXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  xFilesSelected: {
    '0': '%{smart_count} file selected',
    '1': '%{smart_count} file selected',
    '2': '%{smart_count} file selected',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} file added',
    '1': '%{smart_count} file added',
    '2': '%{smart_count} file added',
  },
  xTimeLeft: '%{time} left',
  youCanOnlyUploadFileTypes: 'Only can upload: %{types}',
  youCanOnlyUploadX: {
    '0': 'Only can upload %{smart_count} file',
    '1': 'Only can upload %{smart_count} file',
    '2': 'Only can upload %{smart_count} file',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Please select file at least %{smart_count} file',
    '1': 'Please select file at least %{smart_count} file',
    '2': 'Please select file at least %{smart_count} file',
  },
};
