import { Action } from 'redux';
export interface IFetchGradeListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedGradeAction extends Action {
  id: string;
  data: object;
}
export function fetchGradeListRequested(): Action {
  return {
    type: 'FETCH_GRADE_LIST_REQUESTED',
  };
}
export function setSelectedGrade(id: string, data): ISetSelectedGradeAction {
  return {
    type: 'SET_SELECTED_GRADE',
    id,
    data,
  };
}
export function fetchGradeListFinished(
  data: object
): IFetchGradeListSuccessAction {
  return {
    type: 'FETCH_GRADE_LIST_FINISHED',
    data,
  };
}

export function clearGradeDetail(): Action {
  return {
    type: 'CLEAR_GRADE_DETAIL',
  };
}
