import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGrade from '../../Grade/Store/GradeAction';
import * as ActionSchool from '../Store/SchoolAction';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsSchool from '../Selector/SchoolSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import SchoolComponent from '../Component/SchoolComponent';
import { createStructuredSelector } from 'reselect';

function SchoolContainer(props) {
  const { schoolAction, componentAction, formValue, gradeAction } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    schoolAction.searchSchool(formValue?.name);
    // eslint-disable-next-line
  }, [formValue]);

  useEffect(() => {
    schoolAction.fetchSchoolListRequested();
    schoolAction.resetForm();
    // eslint-disable-next-line
  }, []);

  const handleLessonPlan = params => {
    schoolAction.setSelectedSchool(params);
    schoolAction.fetchSchoolDetailRequested();
    gradeAction.fetchGradeListRequested();
    componentAction.openModal('LessonPlan');
  };

  const handleRefresh = () => {
    schoolAction.fetchSchoolListRequested();
    dispatch(change('schoolForm', 'name', null));
  };

  const handleSchoolGrade = params => {
    schoolAction.setSelectedSchool(params);
    schoolAction.fetchSchoolDetailRequested();
    schoolAction.fetchSchoolScoringCriteriaRequested();
    gradeAction.fetchGradeListRequested();
    componentAction.openModal('SchoolGrade');
  };

  return (
    <>
      <SchoolComponent
        handleRefresh={handleRefresh}
        handleLessonPlan={handleLessonPlan}
        handleSchoolGrade={handleSchoolGrade}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  list: SelectorsSchool.schoolListSelector(),
  loading: SelectorsComponent.isLoading(),
  schoolModalIsShow: SelectorsComponent.modalIsShow('School'),
  token: SelectorsAuth.tokenSelector(),
  formValue: getFormValues('schoolForm'),
});

const mapDispatchToProps = dispatch => ({
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  gradeAction: bindActionCreators(ActionGrade, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(SchoolContainer);
