import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionCurriculum from '../../Curriculum/Store/CurriculumAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as ActionSchool from '../../School/Store/SchoolAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsConfig from '../../Config/Selector/ConfigSelector';
import * as SelectorsLessonPlan from '../Selector/LessonPlanSelector';
import * as SelectorsPeriodicYear from '../../PeriodicYear/Selector/PeriodicYearSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import ModalLessonPlanComponent from '../Component/ModalLessonPlanComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';
import moment from 'moment';

function ModalLessonPlanContainer(props) {
  const {
    componentAction,
    configData,
    periodicYear,
    intl,
    formValue,
    selectedSchool,
    schoolAction,
    lessonPlanAction,
    curriculumAction,
  } = props;

  let startMonth: null | number = null;
  const listMonth: any[] = [];
  const startPeriodicYear = periodicYear?.yearstart;

  for (const iterator of configData) {
    if (iterator.type === 'startmonth') {
      startMonth = parseInt(iterator.value, 10);
    }
  }

  if (startMonth !== null) {
    let indexWeek = 1;
    for (let index = 0; index < 12; index++) {
      for (let ii = 1; ii <= 4; ii++) {
        listMonth.push({
          id: indexWeek,
          name: `${intl.formatMessage({
            id: 'week',
          })} ${ii} ${moment(
            `${startMonth > 12 ? startMonth - 12 : startMonth}-${
              startMonth > 12
                ? `${parseInt(startPeriodicYear, 10) + 1}`
                : `${startPeriodicYear}`
            }`,
            'MM-YYYY'
          ).format('MMMM - YYYY')}`,
        });
        indexWeek++;
      }
      startMonth += 1;
    }
  }

  useEffect(() => {
    if (formValue) {
      const { week } = formValue;
      schoolAction.fetchLessonPlanSchoolRequested(selectedSchool, week);
    }
    // eslint-disable-next-line
  }, [formValue]);

  const handleLessonPlan = (type, id = null, data: any = null) => {
    lessonPlanAction.setSelectedModalAction(type);
    lessonPlanAction.setSelectedLessonPlan(id);
    lessonPlanAction.setSelectedLearningActivity(data);
    curriculumAction.setSelectedCurriculum(data.id);
    componentAction.openModal('FormLessonPlan');
  };

  const handleCancel = () => {
    componentAction.openModal('LessonPlan');
    lessonPlanAction.resetFormLessonPlanWeek();
    lessonPlanAction.clearLessonPlanList();
  };

  return (
    <>
      <ModalLessonPlanComponent
        handleClose={handleCancel}
        handleLessonPlan={handleLessonPlan}
        listMonth={listMonth}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  lessonPlanModalIsShow: SelectorsComponent.modalIsShow('LessonPlan'),
  configData: SelectorsConfig.configListSelector(),
  periodicYear: SelectorsPeriodicYear.activePeriodicYearsSelector(),
  formValue: getFormValues('lessonPlanWeek'),
  selectedSchool: SelectorsSchool.schoolSelectedSelector(),
  listLessonPlan: SelectorsLessonPlan.listLessonPlanSelector(),
  // selectedLessonPlanData: SelectorsLessonPlan.selectedLessonPlanDataSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  schoolAction: bindActionCreators(ActionSchool, dispatch),
  curriculumAction: bindActionCreators(ActionCurriculum, dispatch),
  lessonPlanAction: bindActionCreators(ActionLessonPlan, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalLessonPlanContainer));
