import * as ActionClassroom from '../../Classroom/Store/ClassroomAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionScoring from '../../Scoring/Store/ScoringAction';
import * as ActionStudent from '../Store/StudentAction';
import * as SelectorConfig from '../../Config/Selector/ConfigSelector';
import * as SelectorsClassroom from '../../Classroom/Selector/ClassroomSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGrade from '../../Grade/Selector/GradeSelector';
import * as SelectorsSchool from '../../School/Selector/SchoolSelector';
import * as SelectorsScoring from '../../Scoring/Selector/ScoringSelector';
import * as SelectorsStudent from '../Selector/StudentSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import CButton from '../../../Assets/Components/CButton';
import ModalStudentComponent from '../Component/ModalStudentComponent';
import { SnippetsOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { injectIntl } from 'react-intl';

function ModalStudentContainer(props) {
  const {
    componentAction,
    intl,
    classroomAction,
    scoringAction,
    formValue,
    studentAction,
  } = props;
  useEffect(() => {
    studentAction.searchStudent(formValue?.name);
    // eslint-disable-next-line
  }, [formValue]);
  const handleCancel = () => {
    componentAction.openModal('SchoolStudent');
    classroomAction.clearClassroomDetail();
  };
  const listAction = row => {
    const studentClick = () => {
      componentAction.setSelectedGrade(row.id, row);
      componentAction.openModal('SchoolStudent');
    };

    const buttonData = [
      {
        type: 'warning',
        icon: <SnippetsOutlined />,
        className: 'btnActionDetail',
        onClick: studentClick,
        tooltipTitle: intl.formatMessage({ id: 'scoring' }),
      },
    ];
    return (
      <div className="containerButtonAction">
        <CButton buttonData={buttonData} containerStyle={{ marginBottom: 0 }} />
      </div>
    );
  };

  const handleOnChange = params => {
    if (params) {
      scoringAction.fetchScoringDetailRequested(params);
    }
  };

  const handleOnSubmit = (form, idstudent, period) => {
    scoringAction.submitScoreRequested(idstudent, period, form);
  };

  const handleShowReportCard = (idStudent, period) => {
    scoringAction.showReportCardRequested(idStudent, period);
  };

  return (
    <>
      <ModalStudentComponent
        handleClose={handleCancel}
        listAction={listAction}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
        handleShowReportCard={handleShowReportCard}
        {...props}
      />
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: SelectorsComponent.isLoading(),
  studentModalIsShow: SelectorsComponent.modalIsShow('SchoolStudent'),
  studentList: SelectorsStudent.studentListSelector(),
  scoringList: SelectorsScoring.scoringListSelector(),
  scoringCriteriaList: SelectorsSchool.schoolScoringCriteriaSelector(),
  selectedSchoolName: SelectorsSchool.selectedSchoolNameSelector(),
  selectedGradeName: SelectorsGrade.selectedGradeNameSelector(),
  selectedClassroomName: SelectorsClassroom.selectedClassroomNameSelector(),
  scoringMonth: SelectorConfig.configScoringMonthSelector(),
  formValue: getFormValues('studentForm'),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  studentAction: bindActionCreators(ActionStudent, dispatch),
  scoringAction: bindActionCreators(ActionScoring, dispatch),
  classroomAction: bindActionCreators(ActionClassroom, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ModalStudentContainer));
