import { Button, Empty, Form } from 'maveth';
import { InputText, InputTextNumber } from '../../../Assets/Components/CInput';

import { Field } from 'react-final-form';
import React from 'react';

export default function ScoringComponent(
  props,
  intl,
  idstudent,
  period,
  handleShowReportCard,
  scoringCriteriaList
) {
  const { subscribeField, handleSubmit, invalid, initialValues } = props;

  if (!scoringCriteriaList) {
    return <Empty />;
  }

  return (
    <Form
      className="formLogin"
      layout="vertical"
      onFinish={e => {
        handleSubmit(e);
      }}
    >
      {scoringCriteriaList?.scoring?.map((val, idx) => {
        return (
          <Field
            key={`keyFieldForm${idx}-${idstudent}-${period}`}
            name={`${val}__${idstudent}`}
            component={InputTextNumber}
            label={val}
            subscription={subscribeField}
            showError={true}
            min={0}
            max={100}
            step="0.1"
          />
        );
      })}
      <Field
        key={`keyFieldFormComment-${idstudent}-${period}`}
        name={`comment__${idstudent}`}
        component={InputText}
        label={intl.formatMessage({ id: 'comment' })}
        subscription={subscribeField}
        showError={true}
        textArea
      />
      <Button type="primary" htmlType="submit" disabled={invalid}>
        {intl.formatMessage({ id: 'submit' })}
      </Button>
      <Button
        type="primary"
        className="ant-btn-success"
        onClick={() => handleShowReportCard(idstudent, period)}
        disabled={!initialValues || Object.keys(initialValues).length === 0}
      >
        {intl.formatMessage({ id: 'printReport' })}
      </Button>
    </Form>
  );
}
