import { Action } from 'redux';
import { IFetchActivePeriodicYearSuccessAction } from './PeriodicYearAction';

export const initialState: any = {
  periodicData: null,
};
export default function PeriodicYearReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_ACTIVE_PERIODIC_YEAR_FINISHED': {
      const fetchPeriodicYearListSuccessAction = action as IFetchActivePeriodicYearSuccessAction;
      newState.periodicData = fetchPeriodicYearListSuccessAction.data;
      return { ...newState };
    }
  }
  return state;
}
