import { IAuthAction } from './AuthReducer';

export function setLoginTokenData(dataLogin: object): IAuthAction {
  return {
    type: 'SET_LOGIN_TOKEN',
    dataLogin,
  };
}
export function submitLogin() {
  return {
    type: 'SUBMIT_LOGIN_REQUESTED',
  };
}
export function checkUrlResetPassword(link: string) {
  return {
    type: 'CHECK_URL_RESET_PASSWORD_REQUESTED',
    link,
  };
}
export function resetPasswordRequested() {
  return {
    type: 'RESET_PASSWORD_REQUESTED',
  };
}
export function setResetPasswordRequested(link: string) {
  return {
    type: 'SET_RESET_PASSWORD_REQUESTED',
    link,
  };
}
export function logout() {
  return {
    type: 'LOGOUT',
  };
}
export function submitChangePassword() {
  return {
    type: 'CHANGE_PASSWORD_REQUESTED',
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_CHANGE_PASSWORD',
  };
}
export function resetFormPasswordReset() {
  return {
    type: 'RESET_FORM_RESET_PASSWORD',
  };
}
export function resetFormSetPasswordReset() {
  return {
    type: 'RESET_FORM_SET_RESET_PASSWORD',
  };
}
export function renewToken(lastAction: any = null, url = null) {
  return {
    type: 'RENEW_TOKEN',
    lastAction,
    url,
  };
}
