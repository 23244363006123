import { Action } from 'redux';

export interface IFetchScoringDataSuccessAction extends Action {
  idstudent: string;
  data: object;
}
export interface IScoringAction extends Action {
  idstudent: string;
}

export interface ISubmitScoringAction extends IScoringAction {
  period: string;
  formValue: string;
}
export interface ISetSelectedScoringAction extends Action {
  id: string;
}
export interface IShowReportCardAction extends Action {
  idStudent: string;
}
export function fetchScoringDetailRequested(idstudent): IScoringAction {
  return {
    type: 'FETCH_SCORING_DETAIL_REQUESTED',
    idstudent,
  };
}
export function submitScoreRequested(
  idstudent,
  period,
  formValue
): ISubmitScoringAction {
  return {
    type: 'SUBMIT_SCORING_REQUESTED',
    idstudent,
    period,
    formValue,
  };
}
export function clearScoringDetail(): Action {
  return {
    type: 'CLEAR_SCORING_DETAIL',
  };
}
export function setSelectedScoring(id: string): ISetSelectedScoringAction {
  return {
    type: 'SET_SELECTED_SCORING',
    id,
  };
}
export function fetchScoringDetailFinished(
  idstudent,
  data: object
): IFetchScoringDataSuccessAction {
  return {
    type: 'FETCH_SCORING_DETAIL_FINISHED',
    idstudent,
    data,
  };
}
export function resetForm() {
  return {
    type: 'RESET_FORM_SCORING',
  };
}
export function showReportCardRequested(
  idStudent,
  period
): IShowReportCardAction {
  return {
    type: 'SHOW_REPORT_CARD_REQUESTED',
    idStudent,
    period,
  };
}
