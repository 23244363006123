import {
  ISetPaginationIndexAction,
  ISetPaginationQueryAction,
  ISetPaginationSizeAction,
  ISetPaginationTotalAction,
} from './TableAction';

import { Action } from 'redux';

const defaultTableObj = {
  paginationPageTotal: 0,
  paginationPageSize: 10,
  paginationPageIndex: 0,
  paginationTotalData: 0,
  paginationQuery: '',
};

export const initialState: any = {
  cashbook: defaultTableObj,
  customer: defaultTableObj,
  product: defaultTableObj,
  productOpname: defaultTableObj,
  productOutOfStock: defaultTableObj,
  stockIn: defaultTableObj,
  stockOut: defaultTableObj,
  transaction: defaultTableObj,
};

export default function TransactionReducer(
  state = initialState,
  action: Action = { type: '' }
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_PAGINATION_PAGE_SIZE': {
      const setPaginationIndexAction = action as ISetPaginationSizeAction;
      const table = setPaginationIndexAction.table;
      newState[table].paginationPageSize = setPaginationIndexAction.size;
      return { ...newState };
    }
    case 'SET_PAGINATION_PAGE_INDEX': {
      const setPaginationSizeAction = action as ISetPaginationIndexAction;
      const table = setPaginationSizeAction.table;
      newState[table].paginationPageIndex = setPaginationSizeAction.index;
      return { ...newState };
    }
    case 'SET_PAGINATION_PAGE_TOTAL': {
      const setPaginationTotalAction = action as ISetPaginationTotalAction;
      const table = setPaginationTotalAction.table;
      newState[table].paginationPageTotal = setPaginationTotalAction.total;
      return { ...newState };
    }
    case 'SET_PAGINATION_TOTAL_DATA': {
      const setPaginationTotalAction = action as ISetPaginationTotalAction;
      const table = setPaginationTotalAction.table;
      newState[table].paginationTotalData = setPaginationTotalAction.total;
      return { ...newState };
    }
    case 'SET_PAGINATION_QUERY': {
      const setPaginationQueryAction = action as ISetPaginationQueryAction;
      const table = setPaginationQueryAction.table;
      newState[table].paginationQuery = setPaginationQueryAction.query;
      return { ...newState };
    }
  }
  return state;
}
