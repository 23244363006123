import { Col, Grid, Layout, Row } from 'maveth';

import CModalEsign from '../../../Assets/Components/CModalEsign';
import DrawerContainer from '../Container/DrawerContainer';
import DrawerMenuContainer from '../Container/DrawerMenuContainer';
import { Link } from 'react-router-dom';
import MenuContainer from '../Container/MenuContainer';
import React from 'react';
import logoImage from '../../../Assets/Images/logoalt.png';

// import { MenuOutlined, UserOutlined } from '@ant-design/icons';

const { Header, Footer, Content } = Layout;

export default function TemplateComponent(props) {
  const {
    // loggedUser,
    // handleOnClickDrawer,
    intl,
    // openSider,
    theme,
    showModalESign,
    handleSubmitESign,
    handleShowModalESign,
    eSignData,
    token,
  } = props;

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { xs, md } = screens;

  const isMobile = xs || !md;
  const { secondaryBackground, secondaryColor, background } = theme;
  const extraComponentMenuSignature = () => {
    return (
      <>
        <p>{<b>{intl.formatMessage({ id: 'currentESign' })}</b>}</p>
        {eSignData?.signature ? (
          <div
            className="imgESignPreview"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_API_MAIN_URL}/image/teachersign/${eSignData.signature}?token=${token}})`,
            }}
          />
        ) : null}
        <p>{<b>{intl.formatMessage({ id: 'takeEsignLabel' })}</b>}</p>
      </>
    );
  };

  return (
    <Layout className="layoutContainer">
      <Header
        className="headerTemplate"
        style={{
          backgroundColor: secondaryBackground,
        }}
      >
        <Row>
          <Col span={7}>
            <div className="navbarBrand">
              <Link to="/">
                <img
                  src={logoImage}
                  style={{ height: '30px', marginTop: '-5px' }}
                  alt="logo"
                />
              </Link>
            </div>
          </Col>
          <Col span={17}>
            <MenuContainer
              style={{
                display: isMobile ? 'none' : '',
              }}
            />
          </Col>
        </Row>
      </Header>
      <Content className="containerContent">
        {React.cloneElement(props.children, { intl })}
        <DrawerContainer {...props} />
        <DrawerMenuContainer {...props} />
      </Content>
      <Footer className="footer" style={{ backgroundColor: background }}>
        <p className="copyrightFooter" style={{ color: secondaryColor }}>
          Copyright © {new Date().getFullYear()}
        </p>
      </Footer>
      <CModalEsign
        handleClose={handleShowModalESign}
        showModal={showModalESign}
        handleConfirm={handleSubmitESign}
        header={<b>{intl.formatMessage({ id: 'signature' })}</b>}
        extraContent={extraComponentMenuSignature()}
      />
    </Layout>
  );
}
