import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { Empty } from 'antd';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

function CEmpty(props) {
  const { theme, addtionalStyle, image, imageStyle, description } = props;
  const { thirdColor, sixthBackground } = theme;

  return (
    <Empty
      style={
        addtionalStyle
          ? addtionalStyle
          : {
              background: sixthBackground,
              color: thirdColor,
              margin: 0,
              padding: 30,
            }
      }
      image={image}
      imageStyle={imageStyle}
      description={description}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(CEmpty);
