import Button from 'antd/lib/button';
import React from 'react';
import { Tooltip } from 'antd';

interface IProps {
  buttonData;
  buttonFloat?;
  isLoading?;
  containerStyle?;
  tooltipTitle?;
  tooltipPosition?;
}

export default function CButton(props: IProps) {
  const { buttonData, buttonFloat, isLoading, containerStyle } = props;
  const dataBtn = buttonData.map((value, index) => {
    if (value) {
      const {
        content,
        type,
        icon,
        onClick,
        disabled,
        id,
        className,
        tooltipTitle,
        tooltipPosition,
        hide,
      } = value;
      const renderButton = () => {
        if (!hide) {
          return (
            <Button
              className={className}
              style={{
                marginLeft: buttonFloat === 'right' ? '10px' : 0,
                marginRight: buttonFloat === 'right' ? 0 : '10px',
              }}
              icon={icon}
              type={type}
              onClick={onClick}
              loading={isLoading}
              disabled={disabled}
              id={id}
              key={`button-${index}`}
            >
              {content}
            </Button>
          );
        }
        return null;
      };
      if (tooltipTitle) {
        return (
          <Tooltip
            key={`tooltip-${index}`}
            placement={tooltipPosition}
            title={tooltipTitle}
          >
            {renderButton()}
          </Tooltip>
        );
      } else {
        return renderButton();
      }
    }
    return null;
  });
  return (
    <>
      <span style={buttonFloat ? { float: buttonFloat } : {}}>{dataBtn}</span>
      <span className="clearfix" />
      <div style={containerStyle ? containerStyle : { marginBottom: '30px' }} />
    </>
  );
}
