import * as ActionApp from '../../App/Store/AppAction';
import * as ActionCurriculum from '../Store/CurriculumAction';
import * as ActionLessonPlan from '../../LessonPlan/Store/LessonPlanAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorGrade from '../../Grade/Selector/GradeSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getCurriculumDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorGrade.gradeSelectedSelector());
    const { data } = yield call(
      axios.get,
      `${API_URL}/grade/${id}/curriculum`,
      config
    );
    if (Object.keys(data).length > 0) {
      yield put(ActionCurriculum.fetchCurriculumDetailFinished(data));
      yield put(ActionLessonPlan.fetchLessonPlanDetailRequested());
    }
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getCurriculumDetailAction() {
  yield takeLatest(
    'FETCH_CURRICULUM_DETAIL_REQUESTED',
    getCurriculumDetailProcess
  );
}
