import {
  IFetchGradeListSuccessAction,
  ISetSelectedGradeAction,
} from './GradeAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedGrade: '',
  selectedGradeData: {},
};
export default function GradeReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_GRADE_LIST_FINISHED': {
      const fetchGradeListSuccessAction = action as IFetchGradeListSuccessAction;
      newState.list = fetchGradeListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_GRADE': {
      const setSelectedGradeAction = action as ISetSelectedGradeAction;
      newState.selectedGrade = setSelectedGradeAction.id;
      newState.selectedGradeData = setSelectedGradeAction.data;
      return { ...newState };
    }
    case 'CLEAR_GRADE_DETAIL': {
      newState.selectedGradeData = {};
      newState.selectedGrade = '';
      return { ...newState };
    }
  }
  return state;
}
