import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import DrawerMenuComponent from '../Component/DrawerMenuComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function DrawerMenuContainer(props) {
  const { componentAction, signatureAction } = props;

  useEffect(() => {
    signatureAction.fetchSignatureRequested();
    // eslint-disable-next-line
  }, []);
  const handleDrawerCloseClick = () => {
    componentAction.collapseDrawerMenu();
  };
  const handleDrawerProfileClick = () => {
    componentAction.collapseDrawerProfile();
  };

  return (
    <DrawerMenuComponent
      handleDrawerCloseClick={handleDrawerCloseClick}
      handleDrawerProfileClick={handleDrawerProfileClick}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  drawerMenuIsCollapse: SelectorsComponent.drawerMenuIsColapseSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(DrawerMenuContainer));
