import * as AuthActions from '../../Auth/Store/AuthAction';
import * as ComponentActions from '../../App/Store/ComponentAction';
import * as ConfigActions from '../../Config/Store/ConfigAction';
import * as SelectorTheme from '../../Theme/Selector/ThemeSelector';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import ResetPasswordComponent from '../Component/ResetPasswordComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormSyncErrors } from 'redux-form';
import history from '../../../App/History';
import { injectIntl } from 'react-intl';
import { actions as toastrActions } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

function ResetPasswordContainer(props) {
  const { authAction, isAuthenticated, configAction } = props;

  const parameterData: any = useParams();

  useEffect(() => {
    configAction.fetchConfigListRequested();
    authAction.checkUrlResetPassword(parameterData.link);
    // eslint-disable-next-line
  }, []);

  const submitResetPassword = () => {
    authAction.setResetPasswordRequested(parameterData.link);
  };

  if (isAuthenticated) {
    history.push(`/`);
  }

  return (
    <ResetPasswordComponent
      submitResetPassword={submitResetPassword}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: SelectorsComponent.isLoading(),
  isAuthenticated: SelectorsAuth.tokenSelector(),
  appName: SelectorsComponent.appNameSelector(),
  theme: SelectorTheme.themeSelector(),
  errorFormValue: getFormSyncErrors('setResetPasswordForm'),
});

const mapDispatchToProps = dispatch => ({
  redirect: () => history.push('/resetPassword'),
  componentAction: bindActionCreators(ComponentActions, dispatch),
  authAction: bindActionCreators(AuthActions, dispatch),
  configAction: bindActionCreators(ConfigActions, dispatch),
  toastR: bindActionCreators(toastrActions.showConfirm, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(injectIntl(ResetPasswordContainer));
