import * as ActionAuth from '../../Auth/Store/AuthAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as AuthSelector from '../../Auth/Selector/AuthSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import { toast } from 'react-toastify';

function* getToken() {
  return yield select(AuthSelector.tokenSelector());
}

export function* getCommonConfigHeader() {
  const token = yield call(getToken);
  return {
    headers: { Authorization: `bearer ${token}` },
  };
}
export function* handleErrorProcess(action) {
  let toastMessage;
  try {
    const error = action.errorData;
    const errorJson = error.toJSON();
    if (errorJson.message === 'Network Error') {
      toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id={'networkError'} />
        </span>
      );
    } else {
      if (error.response) {
        const responseStatus = error.response.status;
        if (responseStatus === 401) {
          yield put(ActionAuth.renewToken(action));
        } else if (responseStatus === 400) {
          const errorKey = error.response.data.errorKey[0];
          if (errorKey === 'item.have.null.capitalprice') {
            toastMessage = (
              <span className="capitalFirst">
                <FormattedMessage id={`${errorKey}`} />
                {error.response.data.itemName}
              </span>
            );
          } else {
            toastMessage = (
              <span className="capitalFirst">
                <FormattedMessage id={`${errorKey}`} />
              </span>
            );
          }
        } else if (responseStatus === 403) {
          const forbiddenData = error.response.data.required;
          toastMessage = (
            <span className="capitalFirst">
              <FormattedMessage id={'forbidden'} />. Required : {forbiddenData}
            </span>
          );
        } else {
          toastMessage = (
            <span className="capitalFirst">
              <FormattedMessage id={'unknownError'} />
            </span>
          );
          yield put(ActionComponent.processLoading(false));
        }
      } else {
        toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage id={'unknownError'} />
          </span>
        );
      }
    }
  } catch (error) {
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id={'unknownError'} />
      </span>
    );
  }
  if (toastMessage) {
    toast.error(toastMessage);
  }
}

export function* handleErrorAction() {
  yield takeLatest('HANDLE_ERROR_SAGA', handleErrorProcess);
}
