import * as ActionApp from '../../App/Store/AppAction';
import * as ActionLessonPlan from '../Store/LessonPlanAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as ActionsSchool from '../../School/Store/SchoolAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorCurriculum from '../../Curriculum/Selector/CurriculumSelector';
import * as SelectorLessonPlan from '../Selector/LessonPlanSelector';
import * as SelectorSchool from '../../School/Selector/SchoolSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getLessonPlanDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorCurriculum.selectedCurriculumSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/lessonplan/${id}`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionLessonPlan.setLessonPlanDetail(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitLessonPlanProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);

    const week = yield select(SelectorSchool.selectedWeekSelector());
    const idschool = yield select(SelectorSchool.schoolSelectedSelector());
    const id = yield select(SelectorLessonPlan.selectedLessonPlanSelector());
    const idcurriculum = yield select(
      SelectorCurriculum.selectedCurriculumSelector()
    );
    const maintheme = yield select(SelectorLessonPlan.mainThemeFormSelector());
    const learningactivities = yield select(
      SelectorLessonPlan.learningActivityFormSelector()
    );
    const resource = yield select(SelectorLessonPlan.resourceFormSelector());
    const project = yield select(SelectorLessonPlan.projectFormSelector());
    yield put(ActionsComponent.processLoading(true));
    if (id) {
      yield call(
        axios.put,
        `${API_URL}/lessonplan/${id}`,
        {
          maintheme,
          learningactivities,
          resource,
          project,
        },
        config
      );
    } else {
      yield call(
        axios.post,
        `${API_URL}/lessonplan`,
        {
          idcurriculum,
          maintheme,
          week,
          learningactivities,
          resource,
          project,
        },
        config
      );
    }
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionsSchool.fetchLessonPlanSchoolRequested(idschool, week));
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successSetLessonplan" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
    yield put(ActionLessonPlan.resetForm());
    yield put(ActionLessonPlan.resetFormLessonPlan());
    yield put(ActionLessonPlan.clearLessonPlanDetail());
    yield put(ActionsComponent.openModal('FormLessonPlan'));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getLessonPlanDetailAction() {
  yield takeLatest(
    'FETCH_LESSON_PLAN_DETAIL_REQUESTED',
    getLessonPlanDetailProcess
  );
}
export function* submitLessonPlanAction() {
  yield takeLatest('SUBMIT_LESSON_PLAN_REQUESTED', submitLessonPlanProcess);
}
