import { Action } from 'redux';
export interface IFetchStudentListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedStudentAction extends Action {
  id: string;
}
export interface ISearchStudentAction extends Action {
  name: string;
}
export function fetchStudentListRequested(): Action {
  return {
    type: 'FETCH_STUDENT_LIST_REQUESTED',
  };
}
export function setSelectedStudent(id: string): ISetSelectedStudentAction {
  return {
    type: 'SET_SELECTED_STUDENT',
    id,
  };
}
export function fetchStudentListFinished(
  data: object
): IFetchStudentListSuccessAction {
  return {
    type: 'FETCH_STUDENT_LIST_FINISHED',
    data,
  };
}
export function searchStudent(name: string): ISearchStudentAction {
  return {
    type: 'SEARCH_STUDENT',
    name,
  };
}
