import { Modal, Row } from 'maveth';

import CCard from '../../../Assets/Components/CCard';
import CList from '../../../Assets/Components/CList';
import { FormattedMessage } from 'react-intl';
import ModalClassroomContainer from '../../Classroom/Container/ModalClassroomContainer';
import React from 'react';

export default function FormModalGradeComponent(props) {
  const {
    gradeList,
    gradeModalIsShow,
    handleCancel,
    intl,
    loading,
    selectedSchoolName,
    listAction,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="gradeForm" /> : {selectedSchoolName}
      </>
    );
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'gradeList' })}
      </span>
    );
  };
  const cardContentTable = () => {
    return (
      <Row>
        <CList data={gradeList} style={{ width: '100%' }} action={listAction} />
      </Row>
    );
  };
  const contentComponent = () => {
    return (
      <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
    );
  };
  return (
    <>
      <Modal
        isShow={gradeModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        handleClose={handleCancel}
        isLoading={loading}
      />
      <ModalClassroomContainer />
    </>
  );
}
