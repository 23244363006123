import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { List } from 'antd';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

interface IPropsList {
  data;
  header?;
  footer?;
  size?;
  customKeyLabel?;
  className?;
  style?;
  loading?;
  action?;
}

function CList(props: IPropsList) {
  const {
    data,
    header,
    footer,
    size,
    customKeyLabel,
    className,
    style,
    loading,
    action,
  } = props;

  const renderItem = item => {
    function Action() {
      return <span>{action(item)}</span>;
    }

    return (
      <List.Item actions={[<Action />]}>
        <List.Item.Meta
          title={customKeyLabel ? item[customKeyLabel] : item.name}
        />
      </List.Item>
    );
  };

  return (
    <List
      size={size}
      header={header}
      footer={footer}
      bordered
      dataSource={data}
      className={`cList ${className}`}
      style={style}
      loading={loading}
      renderItem={renderItem}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(CList);
