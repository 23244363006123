import { Action } from 'redux';

export interface IFetchTrimesterSettingSuccessAction extends Action {
  data: object;
}

export function fetchTrimesterSettingRequested(): Action {
  return {
    type: 'FETCH_TRIMESTER_SETTING_REQUESTED',
  };
}
export function fetchActiveTrimesterSettingFinished(
  data: object
): IFetchTrimesterSettingSuccessAction {
  return {
    type: 'FETCH_TRIMESTER_SETTING_FINISHED',
    data,
  };
}
