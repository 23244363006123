import { Action } from 'redux';

export interface IFetchActivePeriodicYearSuccessAction extends Action {
  data: object;
}

export function fetchActivePeriodicYearRequested(): Action {
  return {
    type: 'FETCH_ACTIVE_PERIODIC_YEAR_REQUESTED',
  };
}
export function fetchActivePeriodicYearFinished(
  data: object
): IFetchActivePeriodicYearSuccessAction {
  return {
    type: 'FETCH_ACTIVE_PERIODIC_YEAR_FINISHED',
    data,
  };
}
