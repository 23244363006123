import {
  IDeleteDetailAction,
  IFetchLessonPlanDetailSuccessAction,
  ISetDetailListAction,
  ISetSelectedLearningActivityAction,
  ISetSelectedLessonPlan,
  ISetSelectedModalAction,
  ISetTopicDetailAction,
} from './LessonPlanAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedLessonPlan: null,
  selectedLessonPlanData: {},
  selectedLearningActivity: {},
  lessonPlanModalAction: 'register',
  listSourceDetail: [],
  listProjectDetail: [],
};
export default function LessonPlanReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_LESSON_PLAN_DETAIL': {
      const fetchLessonPlanSuccessAction =
        action as IFetchLessonPlanDetailSuccessAction;
      const data: any = fetchLessonPlanSuccessAction.data;
      newState.selectedLessonPlanData = data;
      return { ...newState };
    }
    case 'SET_LESSON_PLAN_MODAL_ACTION': {
      const setSelectedModalAction = action as ISetSelectedModalAction;
      newState.lessonPlanModalAction = setSelectedModalAction.action;
      return { ...newState };
    }
    case 'SET_SELECTED_LESSON_PLAN': {
      const setSelectedLessonPlan = action as ISetSelectedLessonPlan;
      const id = setSelectedLessonPlan.id;
      newState.selectedLessonPlan = id;
      return { ...newState };
    }
    case 'SET_SELECTED_LEARNING_ACTIVITY': {
      const setSelectedLearningActivityAction =
        action as ISetSelectedLearningActivityAction;
      newState.selectedLearningActivity =
        setSelectedLearningActivityAction.data;
      return { ...newState };
    }
    case 'CLEAR_LESSON_PLAN_DETAIL': {
      newState.selectedLessonPlanData = {};
      return { ...newState };
    }
    case 'CLEAR_LESSON_PLAN_LIST': {
      newState.list = [];
      return { ...newState };
    }
    case 'CLEAR_SELECTED_LESSON_PLAN': {
      newState.selectedLessonPlan = null;
      return { ...newState };
    }
    case 'ADD_SOURCE_DETAIL': {
      const setTopicDetailAction = action as ISetTopicDetailAction;
      const topic = setTopicDetailAction.topic;
      const listSourceDetail = state.listSourceDetail;
      listSourceDetail.push(topic);
      newState.listSourceDetail = listSourceDetail;
      return { ...newState };
    }
    case 'DELETE_SOURCE_DETAIL': {
      const deleteDetailAction = action as IDeleteDetailAction;
      const index = deleteDetailAction.index;
      const topicList = [...newState.listSourceDetail];
      topicList.splice(index, 1);
      newState.listSourceDetail = topicList;
      return { ...newState };
    }
    case 'ADD_PROJECT_DETAIL': {
      const setTopicDetailAction = action as ISetTopicDetailAction;
      const topic = setTopicDetailAction.topic;
      const listProjectDetail = state.listProjectDetail;
      listProjectDetail.push(topic);
      newState.listProjectDetail = listProjectDetail;
      return { ...newState };
    }
    case 'DELETE_PROJECT_DETAIL': {
      const deleteDetailAction = action as IDeleteDetailAction;
      const index = deleteDetailAction.index;
      const topicList = [...newState.listProjectDetail];
      topicList.splice(index, 1);
      newState.listProjectDetail = topicList;
      return { ...newState };
    }
    case 'SET_SOURCE_DETAIL_LIST': {
      const setDetailListAction = action as ISetDetailListAction;
      newState.listSourceDetail = setDetailListAction.data;
      return { ...newState };
    }
    case 'SET_PROJECT_DETAIL_LIST': {
      const setDetailListAction = action as ISetDetailListAction;
      newState.listProjectDetail = setDetailListAction.data;
      return { ...newState };
    }
    case 'FETCH_LESSON_PLAN_SCHOOL_FINISHED': {
      const fetchLessonPlanDetailSuccessAction =
        action as IFetchLessonPlanDetailSuccessAction;
      newState.list = fetchLessonPlanDetailSuccessAction.data;
      return { ...newState };
    }

    case 'RESET_FORM_LESSON_PLAN_DETAIL': {
      newState.lessonPlanModalAction = 'register';
      newState.selectedLessonPlan = null;
      newState.listSourceDetail = [];
      newState.listProjectDetail = [];
      newState.selectedLearningActivity = {};
      return { ...newState };
    }
  }
  return state;
}
