import {
  IFetchStudentListSuccessAction,
  ISearchStudentAction,
  ISetSelectedStudentAction,
} from './StudentAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  originaList: [],
  selectedStudent: '',
};
export default function StudentReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_STUDENT_LIST_FINISHED': {
      const fetchStudentListSuccessAction =
        action as IFetchStudentListSuccessAction;
      newState.list = fetchStudentListSuccessAction.data;
      newState.originaList = fetchStudentListSuccessAction.data;
      return { ...newState };
    }
    case 'SET_SELECTED_STUDENT': {
      const setSelectedStudentAction = action as ISetSelectedStudentAction;
      newState.selectedStudent = setSelectedStudentAction.id;
      return { ...newState };
    }
    case 'SEARCH_STUDENT': {
      const searchStudentAction = action as ISearchStudentAction;
      const searchName = searchStudentAction.name?.toLowerCase();
      let list = [...state.list];
      if (!searchName || searchName === '') {
        list = [...state.originaList];
      } else {
        list = list.filter((item: any) => {
          const name = item.name?.toLowerCase();
          return name.indexOf(searchName) > -1;
        });
      }
      newState.list = list;
      return { ...newState };
    }
  }
  return state;
}
