const FormLessonPlanAddValidation = (values, props) => {
  const validationLang = props.intl.messages.validation;
  const errors: any = {};
  if (!values.learningactivities) {
    errors.learningactivities = validationLang.learningActivityRequired;
  } else if (values.learningactivities.length < 4) {
    errors.learningactivities = validationLang.learningActivityMin;
  } else if (values.learningactivities.length > 255) {
    errors.learningactivities = validationLang.learningActivityMax;
  }
  return errors;
};
export default FormLessonPlanAddValidation;
