import { Button, Col, Modal, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CAlertErrorMessage from '../../../Assets/Components/CAlertErrorMessage';
import { FormattedMessage } from 'react-intl';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/ResetPasswordValidation';

function FormModalUserResetPasswordComponent(props) {
  const {
    resetPassword,
    handleCancel,
    invalid,
    errorFormValue,
    anyTouched,
    intl,
    handleSubmit,
    loading,
  } = props;

  const headerComponent = () => {
    return <FormattedMessage id="userResetPasswordForm" />;
  };
  const footerComponent = () => {
    return (
      <Button
        type="primary"
        disabled={invalid}
        htmlType="submit"
        loading={loading}
      >
        {intl.formatMessage({ id: 'submit' })}
      </Button>
    );
  };
  const contentComponent = () => {
    return (
      <>
        <Row>
          <CAlertErrorMessage
            errorDataForm={errorFormValue}
            isShow={anyTouched && Object.keys(errorFormValue).length > 0}
          />
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={24}>
            <Field
              name="username"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderUsername' })}
              idComponent="inputUsernameForgotPassword"
              label={intl.formatMessage({
                id: 'username',
              })}
            />
            <Field
              name="email"
              component={InputText}
              placeholder={intl.formatMessage({ id: 'placeholderEmail' })}
              idComponent="inputEmailForgotPassword"
              label={intl.formatMessage({
                id: 'email',
              })}
            />
          </Col>
        </Row>
      </>
    );
  };
  return (
    <Modal
      isShow={resetPassword}
      header={headerComponent()}
      content={contentComponent()}
      footer={footerComponent()}
      handleClose={handleCancel}
      isForm={true}
      layout="vertical"
      handleSubmit={handleSubmit}
      isLoading={loading}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '800px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '1000',
        },
      }}
    />
  );
}

const ModalResetPasswordComponent = reduxForm({
  form: 'resetPasswordForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
  validate,
})(FormModalUserResetPasswordComponent);
export default ModalResetPasswordComponent;
