/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import * as React from 'react';

import { DEFAULT_LOCALE } from './i18n';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { selectLocale } from './selectors';

interface IProps {
  locale?: string;
  messages: object;
  children?: any;
}

function index(props: IProps) {
  const { locale, messages, children } = props;
  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages[locale || DEFAULT_LOCALE]}
    >
      {React.Children.only(children)}
    </IntlProvider>
  );
}

const mapStateToProps = createSelector(selectLocale(), locale => ({
  locale,
}));

export default connect(mapStateToProps)(index);
