const ChangePasswordValidation = values => {
  const errors: any = {};
  if (!values.oldpassword) {
    errors.oldpassword = 'Old password required!';
  }
  if (values.oldpassword && values.oldpassword.length < 5) {
    errors.oldpassword = 'Minimal old password length is 5';
  }
  if (!values.newpassword) {
    errors.newpassword = 'New password required!';
  }
  if (values.newpassword && values.newpassword.length < 5) {
    errors.newpassword = 'Minimal new password length is 5';
  }
  if (values.newpassword !== values.confirmpassword) {
    errors.confirmpassword = 'Confirm password required!';
  }
  return errors;
};
export default ChangePasswordValidation;
