const SetResetPasswordValidation = values => {
  const errors: any = {};
  if (!values.password) {
    errors.password = 'Password required!';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Confirm password required!';
  } else {
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password not same!';
    }
  }
  return errors;
};
export default SetResetPasswordValidation;
