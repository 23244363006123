import { createSelector } from 'reselect';

const selectorStudentList = state => state.Student;
const selectorFormStudent = state => state.form.studentForm.values;

// SELECTOR OTHER
export const studentListSelector = () =>
  createSelector(selectorStudentList, state => state.list);
export const studentSelectedSelector = () =>
  createSelector(selectorStudentList, state => state.selectedStudent);
export const studentDetailSelector = () =>
  createSelector(selectorStudentList, state => state.selectedStudentData);
export const studentModalActionSelector = () =>
  createSelector(selectorStudentList, state => state.studentModalAction);
export const selectedStudentNameSelector = () =>
  createSelector(
    selectorStudentList,
    state => state?.selectedStudentData?.name
  );

// SELECTOR FORM
export const formNameSelector = () =>
  createSelector(selectorFormStudent, state => state.name);
export const formTeacherSelector = () =>
  createSelector(selectorFormStudent, state => state.idteacher);
