import { createSelector } from 'reselect';

const selectorTrimesterSettingList = state => state.TrimesterSetting;

// SELECTOR OTHER
export const trimesterSettingSelector = () =>
  createSelector(
    selectorTrimesterSettingList,
    state => state.trimesterSettingData
  );
