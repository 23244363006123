import { HomeOutlined } from '@ant-design/icons';
import React from 'react';

export const configMenuSider = intl => {
  return [
    {
      component: 'menuItem',
      path: '/school',
      icon: <HomeOutlined />,
      label: intl.formatMessage({ id: 'school' }),
      id: 'menuSiderSchool',
      module: 'school',
    },
  ];
};
