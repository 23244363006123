import {
  IFetchConfigListSuccessAction,
  ISetModalDataAction,
  ISetSelectedConfigAction,
} from './ConfigAction';

import { Action } from 'redux';

export const initialState: any = {
  list: [],
  selectedConfig: '',
  selectedConfigData: {},
  configModalAction: 'register',
  scoringMonth: [],
};
export default function ConfigReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_CONFIG_LIST_FINISHED': {
      let startmonth;
      let endmonth;
      const scoringMonth: any = [];
      const fetchConfigListSuccessAction =
        action as IFetchConfigListSuccessAction;
      newState.list = fetchConfigListSuccessAction.data;
      for (const iterator of newState.list) {
        if (iterator.type === 'startmonth') {
          startmonth = parseInt(iterator.value, 10);
          break;
        }
      }
      if (startmonth) {
        for (let index = 0; index < 4; index++) {
          if (index !== 0) {
            startmonth = startmonth + 3;
          }
          endmonth = startmonth + 2;
          scoringMonth.push({
            start: startmonth,
            end: endmonth,
          });
        }
      }
      newState.scoringMonth = scoringMonth;
      return { ...newState };
    }
    case 'SET_SELECTED_CONFIG': {
      const setSelectedConfigAction = action as ISetSelectedConfigAction;
      newState.selectedConfig = setSelectedConfigAction.id;
      return { ...newState };
    }
    case 'SET_CONFIG_MODAL_DATA': {
      const setModalDataAction = action as ISetModalDataAction;
      newState.configModalAction = setModalDataAction.action;
      newState.selectedConfig = setModalDataAction.id;
      newState.selectedConfigData = setModalDataAction.data;
      return { ...newState };
    }
  }
  return state;
}
