import * as ActionApp from '../../App/Store/AppAction';
import * as ActionScoring from '../Store/ScoringAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorPeriodicYear from '../../PeriodicYear/Selector/PeriodicYearSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { FormattedMessage } from 'react-intl';
import React from 'react';
import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';
import { toast } from 'react-toastify';

let toastMessage;

export function* getScoringDetailProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { idstudent } = action;
    const periodicYear = yield select(
      SelectorPeriodicYear.activePeriodicYearsSelector()
    );

    const { data } = yield call(
      axios.get,
      `${API_URL}/scoring/${idstudent}/${periodicYear.id}`,
      config
    );
    if (data !== '') {
      yield put(ActionScoring.fetchScoringDetailFinished(idstudent, data));
    }
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* submitScoringProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { idstudent, period, formValue } = action;

    const scoring: any = [];
    let comment;

    for (const key in formValue) {
      if (Object.prototype.hasOwnProperty.call(action.formValue, key)) {
        const element = action.formValue[key];
        if (key === `comment__${idstudent}`) {
          comment = element;
        } else {
          const keyObj = key.split('__');
          scoring.push({ name: keyObj[0], value: element });
        }
      }
    }

    const idperiodicyear = yield select(
      SelectorPeriodicYear.activePeriodicYearsSelector()
    );
    yield call(
      axios.post,
      `${API_URL}/scoring`,
      {
        idstudent,
        idperiodicyears: idperiodicyear.id,
        period: parseInt(period, 10),
        scoring,
        comment,
      },
      config
    );
    toastMessage = (
      <span className="capitalFirst">
        <FormattedMessage id="successScoringStudent" />
      </span>
    );
    toast.success(toastMessage, {
      className: 'toastSuccessBackground',
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* showReportCardProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const token = yield select(SelectorAuth.tokenSelector());
    const { idStudent, period } = action;

    const idperiodicyear = yield select(
      SelectorPeriodicYear.activePeriodicYearsSelector()
    );
    axios({
      url: `${API_URL}/scoring/report/${period}/${idStudent}/${idperiodicyear.id}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization: `bearer ${token}` },
    }).then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
      }
    });
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getScoringDetailAction() {
  yield takeLatest('FETCH_SCORING_DETAIL_REQUESTED', getScoringDetailProcess);
}
export function* submitScoringAction() {
  yield takeLatest('SUBMIT_SCORING_REQUESTED', submitScoringProcess);
}
export function* showReportCardAction() {
  yield takeLatest('SHOW_REPORT_CARD_REQUESTED', showReportCardProcess);
}
