import { createSelector } from 'reselect';

const selectorLoginForm = state => state.form.loginForm.values;
const selectorResetPasswordForm = state => state.form.resetPasswordForm.values;
const selectorSetResetPasswordForm = state =>
  state.form.setResetPasswordForm.values;
const selectorChangePasswordForm = state =>
  state.form.changePasswordForm.values;
const selectorAuth = state => state.Auth;

export const usernameSelector = () =>
  createSelector(selectorLoginForm, state => state.username);
export const passwordSelector = () =>
  createSelector(selectorLoginForm, state => state.password);

export const tokenSelector = () =>
  createSelector(selectorAuth, state => state.token);
export const authUserSelector = () =>
  createSelector(selectorAuth, state => state.auth.user);

// FORM CHANGE PASSWORD
export const changePasswordOldSelector = () =>
  createSelector(selectorChangePasswordForm, state => state.oldpassword);
export const changePasswordNewSelector = () =>
  createSelector(selectorChangePasswordForm, state => state.newpassword);

// FORM RESET PASSWORD
export const resetPasswordUsernameSelector = () =>
  createSelector(selectorResetPasswordForm, state => state.username);
export const resetPasswordEmailSelector = () =>
  createSelector(selectorResetPasswordForm, state => state.email);

// FORM SET RESET PASSWORD
export const setResetPasswordSelector = () =>
  createSelector(selectorSetResetPasswordForm, state => state.password);
