import {
  AuditOutlined,
  ClusterOutlined,
  HomeOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row } from 'maveth';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CEmpty from '../../../Assets/Components/CEmpty';
import { InputText } from '../../../Assets/Components/CInput';
import ModalGradeContainer from '../../Grade/Container/ModalGradeContainer';
import ModalLessonPlanContainer from '../../LessonPlan/Container/ModalLessonPlanContainer';
import React from 'react';

function FormSchoolComponent(props) {
  const {
    list,
    intl,
    token,
    handleRefresh,
    loading,
    handleSchoolGrade,
    handleLessonPlan,
  } = props;
  const { Meta } = Card;

  const buttonData = [
    {
      type: 'default',
      icon: <ReloadOutlined />,
      onClick: handleRefresh,
      content: intl.formatMessage({ id: 'refresh' }),
      id: 'btnRefresh',
      className: 'ant-btn-success',
    },
  ];
  const renderCard = () => {
    if (list.length === 0) {
      return <CEmpty addtionalStyle={{ width: '100%' }} />;
    }

    return list.map((item, idx) => {
      return (
        <Col
          xs={24}
          sm={12}
          md={8}
          lg={8}
          xl={6}
          xxl={6}
          key={`itemRenderCard-${idx}`}
          style={{ marginBottom: 20 }}
        >
          <Card
            hoverable
            className="cardSchoolList"
            style={{ width: '100%' }}
            cover={
              <div className="divBackgroundSchoolList">
                {item.logo ? (
                  <div
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_MAIN_URL}/image/school/${item.logo}?token=${token}})`,
                    }}
                    className="imgPreviewSchoolList"
                  />
                ) : (
                  <HomeOutlined className="iconSchoolList" />
                )}
              </div>
            }
            actions={[
              <Row>
                <Col xs={12} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Button
                    className="btnActionCardSchoolList"
                    type="primary"
                    icon={<ClusterOutlined />}
                    block
                    size="large"
                    onClick={() => handleSchoolGrade(item.id)}
                  >
                    {intl.formatMessage({ id: 'scoring' })}
                  </Button>
                </Col>
                <Col xs={12} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Button
                    className="ant-btn-success btnActionCardSchoolList"
                    type="default"
                    icon={<AuditOutlined />}
                    block
                    size="large"
                    onClick={() => handleLessonPlan(item.id)}
                  >
                    {intl.formatMessage({ id: 'lessonPlan' })}
                  </Button>
                </Col>
              </Row>,
            ]}
          >
            <Meta title={item.name} />
          </Card>
        </Col>
      );
    });
  };

  const cardContent = () => (
    <>
      <Row>
        <Field
          name="name"
          component={InputText}
          placeholder={intl.formatMessage({
            id: 'placeholderSearchSchoolName',
          })}
          idComponent="inputName"
          className="inputSearchSchoolName"
          formClassName="formSearchClassName"
        />
        <CButton
          buttonData={buttonData}
          buttonFloat={'left'}
          isLoading={loading}
        />
      </Row>
      <Row gutter={30} className="rowCardSchoolList">
        {renderCard()}
      </Row>
    </>
  );

  return (
    <>
      <CCard
        cardTitle={intl.formatMessage({ id: 'schoolList' })}
        cardContent={cardContent()}
      />
      <ModalGradeContainer />
      <ModalLessonPlanContainer />
    </>
  );
}

const SchoolComponent = reduxForm({
  form: 'schoolForm',
  enableReinitialize: true,
  shouldError: () => {
    return true;
  },
})(FormSchoolComponent);
export default SchoolComponent;
