import {
  IFetchScoringDataSuccessAction,
  ISetSelectedScoringAction,
} from './ScoringAction';

import { Action } from 'redux';

export const initialState: any = {
  list: {},
  selectedScoring: '',
  selectedScoringData: {},
};
export default function ScoringReducer(state = initialState, action: Action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_SCORING_DETAIL_FINISHED': {
      const fetchScoringDataSuccessAction =
        action as IFetchScoringDataSuccessAction;
      const { data, idstudent } = fetchScoringDataSuccessAction;
      const dataScore = {};
      dataScore[idstudent] = data;
      newState.list = { ...state.list, ...dataScore };
      return { ...newState };
    }
    case 'SET_SELECTED_SCORING': {
      const setSelectedScoringAction = action as ISetSelectedScoringAction;
      newState.selectedScoring = setSelectedScoringAction.id;
      return { ...newState };
    }
    case 'CLEAR_SCORING_DETAIL': {
      newState.selectedScoringData = {};
      newState.selectedScoring = '';
      return { ...newState };
    }
  }
  return state;
}
