import { createSelector } from 'reselect';

const selectorConfigList = state => state.Config;
const selectorFormConfig = state => state.form.configForm.values;

// SELECTOR OTHER
export const configListSelector = () =>
  createSelector(selectorConfigList, state => state.list);
export const configSelectedSelector = () =>
  createSelector(selectorConfigList, state => state.selectedConfig);
export const configDetailSelector = () =>
  createSelector(selectorConfigList, state => state.selectedConfigData);
export const configModalActionSelector = () =>
  createSelector(selectorConfigList, state => state.configModalAction);
export const configScoringMonthSelector = () =>
  createSelector(selectorConfigList, state => state.scoringMonth);

// SELECTOR FORM
export const formTypeSelector = () =>
  createSelector(selectorFormConfig, state => state.type);
export const formValueSelector = () =>
  createSelector(selectorFormConfig, state => state.value);
