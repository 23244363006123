import { Modal, Row } from 'maveth';

import CCard from '../../../Assets/Components/CCard';
import CList from '../../../Assets/Components/CList';
import { FormattedMessage } from 'react-intl';
import ModalStudentContainer from '../../Student/Container/ModalStudentContainer';
import React from 'react';

export default function FormModalClassroomComponent(props) {
  const {
    classroomList,
    classroomModalIsShow,
    handleCancel,
    intl,
    loading,
    selectedSchoolName,
    listAction,
    selectedGradeName,
  } = props;

  const headerComponent = () => {
    return (
      <>
        <FormattedMessage id="classroomForm" /> : {selectedSchoolName} -{' '}
        {selectedGradeName}
      </>
    );
  };
  const cardTitleTable = () => {
    return (
      <span style={{ fontWeight: 'bolder' }}>
        {intl.formatMessage({ id: 'classroomList' })}
      </span>
    );
  };
  const cardContentTable = () => {
    return (
      <Row>
        <CList
          data={classroomList}
          style={{ width: '100%' }}
          action={listAction}
        />
      </Row>
    );
  };
  const contentComponent = () => {
    return (
      <CCard cardContent={cardContentTable()} cardTitle={cardTitleTable()} />
    );
  };
  return (
    <>
      <Modal
        isShow={classroomModalIsShow}
        header={headerComponent()}
        content={contentComponent()}
        footer={null}
        handleClose={handleCancel}
        isLoading={loading}
      />
      <ModalStudentContainer />
    </>
  );
}
