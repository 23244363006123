import { Action } from 'redux';
export interface IFetchClassroomListSuccessAction extends Action {
  data: object;
}
export interface ISetSelectedClassroomAction extends Action {
  id: string;
  data: object;
}
export function fetchClassroomListRequested(): Action {
  return {
    type: 'FETCH_CLASSROOM_LIST_REQUESTED',
  };
}
export function setSelectedClassroom(
  id: string,
  data: object
): ISetSelectedClassroomAction {
  return {
    type: 'SET_SELECTED_CLASSROOM',
    id,
    data,
  };
}
export function fetchClassroomListFinished(
  data: object
): IFetchClassroomListSuccessAction {
  return {
    type: 'FETCH_CLASSROOM_LIST_FINISHED',
    data,
  };
}
export function clearClassroomDetail(): Action {
  return {
    type: 'CLEAR_CLASSROOM_DETAIL',
  };
}
