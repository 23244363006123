import 'react-quill/dist/quill.snow.css';

import * as SelectorTheme from '../../Modules/Theme/Selector/ThemeSelector';

import { Form, Input, InputNumber, Select, TimePicker } from 'antd';

import CIcon from './CIcon';
import React from 'react';
import ReactQuill from 'react-quill';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

const mapStateToProps = createStructuredSelector({
  theme: SelectorTheme.themeSelector(),
});
const withConnect = connect(mapStateToProps, null);

interface IProps {
  input?;
  idComponent?;
  placeholder?;
  meta?;
  label?;
  disabled?;
  isLoading?;
  theme?;
  formClassName?;
  showError?;
  key?;
  className?;
  style?;
}
interface IPropsRich extends IProps {}

interface IPropsText extends IProps {
  icon?;
  textArea?;
  labelDesc?;
  maxLength?;
  labelCol?;
  wrapperCol?;
  name?;
}

interface IPropsTextNumber extends IProps {
  icon?;
  min?;
  max?;
  defaultValue?;
  step?;
  stringMode?;
}

interface IPropsSelect extends IProps {
  icon?;
  data;
  dafaultItemName;
  additionalHandleChangeFunc?;
  customKeyValue?;
  customKeyLabel?;
  showSearch?;
  onSearch?;
  filterOption?;
  addLabel?;
  addAction?;
  descriptionValue?;
}

const defaultStyle = { color: 'rgba(0,0,0,.25)' };
const defaultStyleInput = { width: '100%' };

const PlainInputText = (props: IPropsText) => {
  const {
    input,
    meta: { touched, error },
    placeholder,
    idComponent,
    icon,
    label,
    textArea,
    labelDesc,
    disabled,
    isLoading,
    maxLength,
    labelCol,
    wrapperCol,
    theme,
    className,
    formClassName,
  } = props;

  const { thirdColor } = theme;

  const handleOnChange = e => input.onChange(e.target.value);
  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={
        touched && error !== undefined ? 'error' : labelDesc ? 'warning' : ''
      }
      help={touched && error !== undefined ? error : labelDesc ? labelDesc : ''}
      label={<span style={{ color: thirdColor }}>{labelTitle}</span>}
      colon={false}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      className={formClassName}
    >
      {textArea ? (
        <Input.TextArea
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          autoSize={{ minRows: 8 }}
          disabled={disabled}
          maxLength={maxLength}
          loading={isLoading}
          className={className}
        />
      ) : (
        <Input
          placeholder={placeholder}
          prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
          onChange={handleOnChange}
          onBlur={input.onBlur}
          id={idComponent}
          value={input.value}
          disabled={disabled}
          maxLength={maxLength}
          loading={isLoading}
          className={className}
        />
      )}
    </Form.Item>
  );
};
const PlainInputTextNumber = (props: IPropsTextNumber) => {
  const {
    input,
    placeholder,
    meta: { error },
    idComponent,
    label,
    min,
    max,
    defaultValue,
    disabled,
    theme,
    showError,
    // stringMode,
    step,
    key,
  } = props;
  // const { name } = input;
  const { thirdColor } = theme;

  const labelTitle = label ? (
    <span>
      <b className="capital" style={{ color: thirdColor }}>
        {label}
      </b>
    </span>
  ) : null;
  const handleOnChange = e => {
    input.onChange(e);
  };

  return (
    <Form.Item
      validateStatus={showError && error !== undefined ? 'error' : ''}
      help={showError && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <InputNumber
        style={defaultStyleInput}
        placeholder={placeholder}
        id={idComponent}
        min={min}
        max={max ? max : 999999999999999}
        defaultValue={defaultValue}
        disabled={disabled}
        // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        // parser={value => (value ? value.split('.').join('') : '')}
        value={input.value}
        onChange={handleOnChange}
        step={step}
        key={key ? undefined : `field${key}`}
        // onBlur={input.onBlur}
      />
    </Form.Item>
  );
};
const PlainInputRich = (props: IPropsRich) => {
  const {
    input,
    meta: { touched, error },
    label,
  } = props;

  const labelTitle = label ? (
    <span>
      <b className="capital">{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
    >
      <ReactQuill
        theme="snow"
        value={input.value}
        modules={{
          toolbar: [[{ list: 'ordered' }, { list: 'bullet' }]],
        }}
        onBlur={() => input.onBlur()}
        onChange={e => input.onChange(e)}
      />
    </Form.Item>
  );
};
const PlainInputSelect = ({
  input,
  meta: { touched, error },
  data,
  dafaultItemName,
  idComponent,
  label,
  additionalHandleChangeFunc,
  customKeyValue,
  customKeyLabel,
  showSearch,
  onSearch,
  isLoading,
  filterOption,
  disabled,
  addLabel,
  addAction,
  descriptionValue,
  theme,
  className,
  style,
}: IPropsSelect) => {
  const { thirdColor } = theme;

  const items = data.map((item, key) => (
    <Select.Option
      id={`optionValue${label}-${key}`}
      key={item.id}
      value={customKeyValue ? item[customKeyValue] : item.id}
      name={item.name}
      className={className}
      style={style}
    >
      {descriptionValue ? (
        <>
          <p className="selectDescTitle">
            {customKeyLabel ? item[customKeyLabel] : item.name}
          </p>
          <p className="selectDescDecription">{item[descriptionValue]}</p>
        </>
      ) : customKeyLabel ? (
        item[customKeyLabel]
      ) : (
        item.name
      )}
    </Select.Option>
  ));
  const handleOnChange = e => {
    input.onChange(e);
    if (additionalHandleChangeFunc) {
      additionalHandleChangeFunc();
    }
  };
  const labelTitle = (
    <span>
      <b className="capital" style={{ color: thirdColor }}>
        {label}
      </b>
    </span>
  );
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Select
        onChange={handleOnChange}
        id={idComponent}
        value={input.value}
        style={defaultStyleInput}
        showSearch={showSearch}
        optionFilterProp="name"
        filterOption={filterOption}
        onSearch={onSearch}
        loading={isLoading}
        disabled={disabled}
        dropdownRender={menu => (
          <div>
            {menu}
            {addLabel ? (
              <div
                className="btnAddItemSelect"
                onMouseDown={e => e.preventDefault()}
                onClick={addAction}
              >
                <CIcon type="PlusOutlined" /> {addLabel}
              </div>
            ) : null}
          </div>
        )}
      >
        {dafaultItemName ? (
          <Select.Option value="">{dafaultItemName}</Select.Option>
        ) : null}
        {items}
      </Select>
    </Form.Item>
  );
};

const PlainInputPassword = ({
  input,
  meta: { touched, error },
  idComponent,
  icon,
  label,
  placeholder,
  theme,
}: IPropsText) => {
  const handleOnChange = e => input.onChange(e.target.value);
  const { thirdColor } = theme;

  const labelTitle = label ? (
    <span>
      <b className="capital" style={{ color: thirdColor }}>
        {label}
      </b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Input.Password
        prefix={icon ? <CIcon type={icon} style={defaultStyle} /> : null}
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

const PlainInputTimePicker = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  formatTime,
  theme,
}) => {
  const { thirdColor } = theme;

  const handleOnChange = e => input.onChange(e);
  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
    </span>
  );
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={<span style={{ color: thirdColor }}>{labelTitle}</span>}
      colon={false}
    >
      <TimePicker
        onChange={handleOnChange}
        onBlur={input.onBlur}
        id={idComponent}
        value={input.value !== '' ? moment(input.value, 'HH:mm:ss') : undefined}
        defaultOpenValue={moment('12:00:00', 'HH:mm:ss')}
        disabled={disabled}
        format={formatTime ? formatTime : 'HH:mm:ss'}
      />
    </Form.Item>
  );
};
export const InputText = compose(withConnect)(PlainInputText);
export const InputTextNumber = compose(withConnect)(PlainInputTextNumber);
export const InputSelect = compose(withConnect)(PlainInputSelect);
export const InputPassword = compose(withConnect)(PlainInputPassword);
export const InputTimePicker = compose(withConnect)(PlainInputTimePicker);
export const InputRich = compose(withConnect)(PlainInputRich);
