import {
  ICollapseDrawerProfile,
  ILoadingAction,
  ILoadingAlternateAction,
  IOpenLightBoxAction,
  IOpenModalAction,
} from './ComponentAction';

import { Action } from 'redux';

export const initialState: any = {
  drawerProfileIsColapse: true,
  drawerMenuIsColapse: false,
  isLoading: false,
  isLoadingAlternate: false,
  showModalChangePassword: false,
  showModalConfig: false,
  showModalSchool: false,
  showModalLessonPlan: false,
  showModalSchoolGrade: false,
  showModalSchoolClassroom: false,
  showModalUser: false,
  showModalUserChangePassword: false,
  showModalUserResetPassword: false,
  showModalESign: false,
};

export default function ComponentReducer(state = initialState, action: Action) {
  if (!action) {
    return state;
  }
  const newState = Object.assign({}, state);

  switch (action.type) {
    case 'OPEN_DRAWER_PROFILE':
      const collapseDrawerProfile = action as ICollapseDrawerProfile;
      const { isOpen } = collapseDrawerProfile;
      newState.drawerProfileIsColapse = isOpen
        ? isOpen
        : !state.drawerProfileIsColapse;
      return { ...newState };

    case 'OPEN_DRAWER_MENU':
      newState.drawerMenuIsColapse = !state.drawerMenuIsColapse;
      return { ...newState };

    case 'PROCESS_LOADING': {
      const loadingAction = action as ILoadingAction;
      newState.isLoading = loadingAction.isLoading;
      return { ...newState };
    }

    case 'PROCESS_LOADING_ALTERNATE': {
      const loadingAlternateAction = action as ILoadingAlternateAction;
      newState.isLoadingAlternate = loadingAlternateAction.isLoading;
      return { ...newState };
    }

    case 'SHOW_MODAL': {
      const openModalAction = action as IOpenModalAction;
      const component = openModalAction.component;
      newState[`showModal${component}`] = !state[`showModal${component}`];
      return { ...newState };
    }

    case 'OPEN_LIGHTBOX': {
      const openLightBoxAction = action as IOpenLightBoxAction;
      const component = `lightBox${openLightBoxAction.component}IsOpen`;
      newState[component] = !state[component];
      return { ...newState };
    }
  }
  return state;
}
