import * as SelectorComponent from '../../App/Selector/AppSelector';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsTheme from '../../Theme/Selector/ThemeSelector';
import * as actionComponent from '../../App/Store/ComponentAction';

import { bindActionCreators, compose } from 'redux';

import MenuComponent from '../Component/MenuComponent';
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

function MenuContainer(props) {
  const { componentAction } = props;

  const handleShowModalESign = () => {
    componentAction.openModal('ESign');
  };
  const handleOnClickDrawer = () => {
    componentAction.collapseDrawerProfile();
  };
  return (
    <MenuComponent
      handleShowModalESign={handleShowModalESign}
      handleOnClickDrawer={handleOnClickDrawer}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  user: SelectorsAuth.authUserSelector(),
  appName: SelectorComponent.appNameSelector(),
  theme: SelectorsTheme.themeSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(actionComponent, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(MenuContainer));
