import * as ActionApp from '../../App/Store/AppAction';
import * as ActionGrade from '../Store/GradeAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';
import * as SelectorSchool from '../../School/Selector/SchoolSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getGradeListProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseAPISelector());
    const config = yield call(getCommonConfigHeader);
    const id = yield select(SelectorSchool.schoolSelectedSelector());
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${API_URL}/school/${id}/grade`,
      config
    );
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionGrade.fetchGradeListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getGradeListAction() {
  yield takeLatest('FETCH_GRADE_LIST_REQUESTED', getGradeListProcess);
}
