import * as ActionAuth from '../../Auth/Store/AuthAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorTheme from '../../Theme/Selector/ThemeSelector';
import * as SelectorsAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';

import { bindActionCreators, compose } from 'redux';

import DrawerComponent from '../Component/DrawerComponent';
import React from 'react';
import { ToastConfirm } from '../../../Assets/Components/CToast';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';

function DrawerContainer(props) {
  const { componentAction, authAction, intl } = props;
  const handleDrawerProfileClick = () => {
    componentAction.collapseDrawerProfile();
  };
  const handleChangePassword = () => {
    componentAction.openModal('ChangePassword');
    componentAction.collapseDrawerProfile();
  };
  const handleLogout = () => {
    const onOk = () => {
      authAction.logout();
      componentAction.collapseDrawerProfile();
    };
    const toastrConfirmOptions = ToastConfirm(
      onOk,
      null,
      intl.formatMessage({ id: 'logoutConfirmation' })
    );
    toastr.confirm('', toastrConfirmOptions);
  };
  return (
    <DrawerComponent
      handleDrawerProfileClick={handleDrawerProfileClick}
      handleLogout={handleLogout}
      handleChangePassword={handleChangePassword}
      {...props}
    />
  );
}

const mapStateToProps = createStructuredSelector({
  drawerProfileIsCollapse: SelectorsComponent.drawerProfileIsColapseSelector(),
  loggedUser: SelectorsAuth.authUserSelector(),
  theme: SelectorTheme.themeSelector(),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  authAction: bindActionCreators(ActionAuth, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(DrawerContainer));
