import { Form } from 'react-final-form';
import React from 'react';

interface IForm {
  onSubmit;
  subscription?;
  component?;
  validate?;
  destroyOnUnregister?;
  initialValues?;
}

export default function CForm(props: IForm) {
  const { onSubmit, subscription, component, validate, initialValues } = props;
  const subscribeField = {
    error: true,
    value: true,
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      subscription={
        subscription
          ? subscription
          : {
              invalid: true,
              pristine: true,
            }
      }
      render={propsRender =>
        component({
          ...propsRender,
          subscribeField,
        })
      }
      validate={validate}
    />
  );
}

export const FormHOC = (ComposedDocument, formProps = {}) => {
  const component = (props: IForm) => {
    const { onSubmit, initialValues } = props;
    const subscribedField = {
      error: true,
      value: true,
    };

    return (
      <Form
        {...formProps}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={propsrender => (
          <ComposedDocument
            form={{ ...propsrender, subscribedField }}
            {...props}
          />
        )}
      ></Form>
    );
  };
  return component;
};
