import { createSelector } from 'reselect';

const selectorLessonPlan = state => state.LessonPlan;
const selectorFormLessonPlan = state => state.form.lessonPlanForm.values;

// SELECTOR OTHER
export const listLessonPlanSelector = () =>
  createSelector(selectorLessonPlan, state => state.list);
export const selectedLessonPlanSelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLessonPlan);
export const selectedLessonPlanDataSelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLessonPlanData);
export const selectedLearningActivitySelector = () =>
  createSelector(selectorLessonPlan, state => state.selectedLearningActivity);

// SELECTOR FORM
export const mainThemeFormSelector = () =>
  createSelector(selectorFormLessonPlan, state => state.mainTheme);
export const learningActivityFormSelector = () =>
  createSelector(selectorFormLessonPlan, state => state.learningactivities);
export const resourceFormSelector = () =>
  createSelector(selectorFormLessonPlan, state => state.resource);
export const projectFormSelector = () =>
  createSelector(selectorFormLessonPlan, state => state.project);
