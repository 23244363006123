import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionConfig from '../../Config/Store/ConfigAction';
import * as ActionLanguage from '../../Internationalization/actions';
import * as ActionPeriodicYear from '../../PeriodicYear/Store/PeriodicYearAction';
import * as ActionSignature from '../../Signature/Store/SignatureAction';
import * as ActionTheme from '../../Theme/Store/ThemeActions';
import * as ActionTrimesterSetting from '../../TrimesterSetting/Store/TrimesterSettingAction';
import * as SelectorAuth from '../../Auth/Selector/AuthSelector';
import * as SelectorComponent from '../../App/Selector/AppSelector';
import * as SelectorLocale from '../../Internationalization/selectors';
import * as SelectorPeriodicYear from '../../PeriodicYear/Selector/PeriodicYearSelector';
import * as SelectorTheme from '../../Theme/Selector/ThemeSelector';
import * as SelectorsSignature from '../../Signature/Selector/SignatureSelector';
import * as SelectorsTrimesterSetting from '../../TrimesterSetting/Selector/TrimesterSettingSelector';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import { ConfigProvider } from 'maveth';
import TemplateComponent from '../Component/TemplateComponent';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import en from 'antd/lib/locale/en_US';
import id from 'antd/lib/locale/id_ID';
import { injectIntl } from 'react-intl';

function TemplateContainer(props) {
  const {
    componentAction,
    language,
    languageAction,
    themeAction,
    themeSetting,
    locale,
    periodicYear,
    periodicYearAction,
    signatureAction,
    trimesterSetting,
    trimesterSettingAction,
    configAction,
  } = props;

  useEffect(() => {
    if (language) {
      languageAction.changeLocale(language);
    }
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    configAction.fetchConfigListRequested();
    // eslint-disable-next-line
  }, [language]);

  useEffect(() => {
    if (!periodicYear) {
      periodicYearAction.fetchActivePeriodicYearRequested();
    }
    // eslint-disable-next-line
  }, [periodicYear]);

  useEffect(() => {
    if (!trimesterSetting) {
      trimesterSettingAction.fetchTrimesterSettingRequested();
    }
    // eslint-disable-next-line
  }, [trimesterSetting]);

  useEffect(() => {
    if (themeSetting) {
      themeAction.changeTheme(themeSetting);
    }
    // eslint-disable-next-line
  }, [themeSetting]);

  function openSider() {
    componentAction.collapseDrawerMenu();
  }
  const handleShowModalESign = () => {
    componentAction.openModal('ESign');
  };
  const handleSubmitESign = val => {
    signatureAction.setESignDataForm(val);
    signatureAction.submitSignatureRequested();
  };

  return (
    <ConfigProvider locale={locale === 'id' ? id : en}>
      <TemplateComponent
        openSider={openSider}
        handleShowModalESign={handleShowModalESign}
        handleSubmitESign={handleSubmitESign}
        {...props}
      />
    </ConfigProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  loggedUser: SelectorAuth.authUserSelector(),
  locale: SelectorLocale.selectLocale(),
  themeMode: SelectorTheme.themeIsDarkSelector(),
  theme: SelectorTheme.themeSelector(),
  periodicYear: SelectorPeriodicYear.activePeriodicYearsSelector(),
  trimesterSetting: SelectorsTrimesterSetting.trimesterSettingSelector(),
  eSignData: SelectorsSignature.signatureSelector(),
  token: SelectorAuth.tokenSelector(),
  showModalESign: SelectorComponent.modalIsShow('ESign'),
});

const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  languageAction: bindActionCreators(ActionLanguage, dispatch),
  periodicYearAction: bindActionCreators(ActionPeriodicYear, dispatch),
  trimesterSettingAction: bindActionCreators(ActionTrimesterSetting, dispatch),
  themeAction: bindActionCreators(ActionTheme, dispatch),
  signatureAction: bindActionCreators(ActionSignature, dispatch),
  configAction: bindActionCreators(ActionConfig, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(injectIntl(TemplateContainer));
