import * as ActionApp from '../../App/Store/AppAction';
import * as ActionPeriodicYear from '../Store/PeriodicYearAction';
import * as SelectorConfig from '../../App/Selector/AppSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getActivePeriodicYearProcess(action) {
  try {
    const API_URL = yield select(SelectorConfig.baseMainAPISelector());
    const config = yield call(getCommonConfigHeader);
    const { data } = yield call(
      axios.get,
      `${API_URL}/periodicyear/activeperiodic`,
      config
    );
    yield put(ActionPeriodicYear.fetchActivePeriodicYearFinished(data));
  } catch (error) {
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getActivePeriodicYearAction() {
  yield takeLatest(
    'FETCH_ACTIVE_PERIODIC_YEAR_REQUESTED',
    getActivePeriodicYearProcess
  );
}
