import React, { useRef } from 'react';

import CButton from './CButton';
import { Modal } from 'maveth';
import SignatureCanvas from 'react-signature-canvas';
import { injectIntl } from 'react-intl';
import { toast } from 'react-toastify';

interface IProps {
  handleClose;
  showModal;
  handleConfirm;
  title?;
  header?;
  extraContent?;
  intl?;
}
function CModalEsign(props: IProps) {
  const {
    handleClose,
    showModal,
    handleConfirm,
    title,
    header,
    extraContent,
    intl,
  } = props;
  const refSignature = useRef<any>();

  const defaultModalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      maxWidth: '95vw',
      width: '800px',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '9999',
    },
  };

  const handleClear = () => {
    if (refSignature.current !== null) {
      refSignature.current.clear();
    }
  };
  const handleCapture = () => {
    let toastMessage;
    if (refSignature.current !== null) {
      const dataSignature = refSignature.current.toDataURL();
      const isEmpty = refSignature.current.isEmpty();
      const area = refSignature.current.getTrimmedCanvas();

      if (isEmpty) {
        toastMessage = (
          <span className="capitalFirst">
            {intl.formatMessage({ id: 'signatureNullValidation' })}
          </span>
        );
        toast.error(toastMessage);
      } else if (area.width < 50 || area.height < 50) {
        toastMessage = (
          <span className="capitalFirst">
            {intl.formatMessage({ id: 'signatureMin' })}
          </span>
        );
        toast.error(toastMessage);
      } else {
        handleConfirm(dataSignature);
      }
    }
  };
  const dataButton = [
    {
      type: 'primary',
      id: `buttonClearSignature`,
      className: 'btnBackForm',
      content: intl.formatMessage({ id: 'clear' }),
      onClick: handleClear,
    },
    {
      type: 'primary',
      id: `buttonSubmitSignatur`,
      className: 'btnSaveForm',
      content: intl.formatMessage({ id: 'save' }),
      onClick: handleCapture,
    },
  ];

  const contentComponent = () => {
    return (
      <React.Fragment>
        {extraContent}
        {title}
        <SignatureCanvas
          penColor="black"
          canvasProps={{ className: 'signatureCanvas' }}
          ref={refSignature}
        />
        <div style={{ marginTop: '30px' }}>
          <CButton buttonData={dataButton} buttonFloat="right" />
        </div>
      </React.Fragment>
    );
  };
  return (
    <Modal
      isShow={showModal}
      handleClose={handleClose}
      header={header}
      content={contentComponent()}
      footer={null}
      modalstyles={defaultModalstyles}
    />
  );
}

export default injectIntl(CModalEsign);
