import * as ActionApp from '../../App/Store/AppAction';
import * as ActionConfig from '../Store/ConfigAction';
import * as ActionsComponent from '../../App/Store/ComponentAction';
import * as SelectorAppConfig from '../../App/Selector/AppSelector';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getCommonConfigHeader } from '../../App/Saga/AppSaga';

export function* getConfigListProcess(action) {
  try {
    const API_URL = yield select(SelectorAppConfig.baseMainAPISelector());
    const config = yield call(getCommonConfigHeader);
    let appName = '';
    yield put(ActionsComponent.processLoading(true));
    const { data } = yield call(axios.get, `${API_URL}/config`, config);
    data.forEach(element => {
      if (element.type === 'appname') {
        appName = element.value;
      }
    });
    yield put(ActionApp.setAppName(appName));
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionConfig.fetchConfigListFinished(data));
  } catch (error) {
    yield put(ActionsComponent.processLoading(false));
    yield put(ActionApp.handleError(action, error));
  }
}

export function* getConfigListAction() {
  yield takeLatest('FETCH_CONFIG_LIST_REQUESTED', getConfigListProcess);
}
