const ResetPasswordValidation = values => {
  const errors: any = {};
  if (!values.username) {
    errors.username = 'Username required!';
  }
  if (!values.email) {
    errors.email = 'Email required!';
  } else {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Email format not valid!';
    }
  }
  return errors;
};
export default ResetPasswordValidation;
